import { React } from "react";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const SelectCampaign = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  onFocus,
}) => {

  const { fetchData, setDefaultResult, loading, defaultOptions } =
  useAutoCompleteApiCallHandler({
    url: "crm/campaigns-autocomplete",
    setParams: (searchKey) => ({ name: searchKey }),
    setOptions: (option) => setOptionData(option),
    onFocus: onFocus,
  });

const setOptionData = (option) => ({
  label: option.name,
  value: keyName ? option[keyName] : option.id,
});


  return (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchData}
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      isLoading={loading}
      isClearable
      name={name ? name : "campaign"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={`custom-select ${
        error ? "form-control ps-0 py-0 is-invalid" : ""
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
            ? state.isSelected
              ? "#192E49"
              : "#e1e2ea"
            : state.isSelected
            ? "#696c96"
            : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

SelectCampaign.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectCampaign;
