import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  BiComment,
  BiDoughnutChart,
  BiLabel,
  BiSupport,
  BiTask,
} from "react-icons/bi";
import {
  Link,
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
  Outlet,
} from "react-router-dom";
import ProfileImage from "assets/img/Avathar/profile.png";
import { AiOutlineCodeSandbox, AiOutlineNodeCollapse } from "react-icons/ai";
import {
  FaEye,
  FaRegCalendarMinus,
  FaRegCalendarPlus,
  FaUserGraduate,
} from "react-icons/fa";
import Collaborator from "../../common/ViewPages/Common/Collaborator/Collaborator";
import "./TaskOverview.scss";
import { TbClockBolt, TbMapPinFilled, TbRefreshDot } from "react-icons/tb";
import Labels from "../../common/ViewPages/Common/Labels/Labels";
import {
  MdAttachFile,
  MdDescription,
  MdOutlineAutoGraph,
  MdOutlineNewLabel,
  MdOutlinePriorityHigh,
} from "react-icons/md";
import ActivityTimeLine from "../../common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";
import { apiCall } from "helpers/apiCalls";
import DoughnutChart from "module/common/Charts/DoughnutChart";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import { AuthWizardContext, StoreContext } from "context/Context";
import DigitalTimer from "module/common/Timer/DigitalTimer";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import TimeSheetLog from "module/common/ViewPages/Common/TimeSheet/TimeSheetLog";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import PieChart from "module/common/Charts/PieChart";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import ImagePreview from "module/common/ImagePreview/ImagePreview";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import TimerConfirm from "module/common/Timer/TimerConfirm";
import {
  CloneButton,
  CustomDeleteButton,
  CustomEditButton,
  NotifyButton,
  RedirectButton,
} from "module/common/Buttons/AppButtons";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import DeleteWarning from "module/common/Warnings/DeleteWarning";
import removeEmptyFields from "helpers/removeEmptyFields";
import LabelsForm from "module/Labels/LabelsForm";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import Reminders from "module/common/ViewPages/Common/Reminders/Reminders";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import { BsCalendar2Range } from "react-icons/bs";
import { IoMdTime } from "react-icons/io";
import { HiOutlineStatusOnline } from "react-icons/hi";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import TicketInfo from "module/Tickets/TicketInfo/TicketInfo";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { FiPackage } from "react-icons/fi";
import { stringLimiter } from "helpers/utils";
import { RxActivityLog } from "react-icons/rx";
import { GrDocumentTime } from "react-icons/gr";
import UpdateAssigneeListDropDown from "components/common/DropDown/UpdateAssigneeListDropDown";

const TaskOverview = () => {
  const Translate = useAxisproTranslate();
  const { overviewId, itemInfoId, itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [taskData, setTaskData] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collabList, setCollabList] = useState({});
  const { dispatch, user } = useContext(AuthWizardContext);
  const [hasMatch, setHasMatch] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(false);
  const [reloadLabels, setReloadLabels] = useState(false);
  const [statusArray, setStatusArray] = useState([]);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const location = useLocation();
  const renderFrom = location.pathname.split("/")[1];
  const commonSearchParams = createSearchParams({ ...allQueryParams });
  const activeTab = queryParams.get("info_tab");
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [hoveredStatus, setHoveredStatus] = useState(null);
  const [notifyLoading, setNotifyLoading] = useState();
  const [ticketInfo, setTicketInfo] = useState({
    show: false,
    id: "",
  });
  const permission = {
    store: GetAcodaxPermission("TASK", "create"),
    updateTaskAssignee: GetAcodaxPermission("TA", "update"),
    update: GetAcodaxPermission("TASK", "update"),
    delete: GetAcodaxPermission("TASK", "delete"),
    updateTaskStatus: GetAcodaxPermission("TS", "update"),
    showSprint: GetAcodaxPermission("SPRINT", "show"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showTicket: GetAcodaxPermission("TICKET", "show"),
  };
  const { addIitemToStore } = useContext(StoreContext);

  const [deleteModal, setDeleteModal] = useState(false);
  const activeRoute =
    renderFrom === "tasks"
      ? `/tasks/list`
      : renderFrom === "sprints"
      ? `/sprints/info/${itemInfoId}`
      : `/project/info/${itemInfoId}`;

  const handleDeleteOpen = () => setDeleteModal(true);
  const [labelForm, setLabelForm] = useState(false);

  const handleDeleteClose = (status) => {
    setDeleteModal(false);
    if (status !== "error") {
      navigate(
        `${activeRoute}?${createSearchParams(
          removeEmptyFields({
            ...allQueryParams,
            tab: "",
            page: 1,
          })
        )}`
      );
    }
  };

  const handleClose = () => setShow(false);

  const handleTimerSection = () => {
    if (taskData?.running_status === true) {
      setShow(true);
    } else {
      handleTimer();
    }
  };

  const handleUserProfileArea = (id) => {
    if (id) {
      setUserProfile(id);
      setShowUserProfileArea(true);
    } else {
      setUserProfile();
      setShowUserProfileArea(false);
    }
  };

  const options = {
    title: false,
    legend: {
      show: false,
    },
    series: [
      {
        name: "Pie Chart",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: taskData?.contributions?.map((item, key) => ({
          value: item.contribution_in_seconds,
          name: item.name,
        })),
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          show: true,
          formatter: function (params) {
            const hours = Math.floor(params.data.value / 3600);
            const minutes = Math.floor((params.data.value % 3600) / 60);
            const remainingSeconds = params.data.value % 60;

            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");
            const formattedSeconds = String(remainingSeconds).padStart(2, "0");

            return `${params.data.name} \n ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          },
        },
      },
    ],
  };

  const optionsDoughnut = {
    cutout: "60%",

    plugins: {
      legend: {
        display: false,
        // position: "bottom",
      },
      doughnutlabel: {
        labels: [
          {
            text: "Doughnut Chart",
            font: {
              size: 20,
            },
            color: "#000",
            position: "center",
          },
          {
            text: "Total: 41",
            font: {
              size: 16,
            },
            color: "#000",
            position: "inner",
          },
        ],
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const dataDoughnut = {
    labels: taskData?.contributions?.map((item, key) => item.name),
    datasets: [
      {
        data: taskData?.contributions?.map(
          (item, key) => item.contribution_percentage
        ),
      },
    ],
  };

  const hasNonZeroValue = dataDoughnut?.datasets[0]?.data?.some(
    (value) => value !== 0
  );

  useEffect(() => {
    if (overviewId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  const getData = async () => {
    setLoading(true);

    const data = await apiCall({
      url: `crm/task/${overviewId}`,
    });

    setFormData({ task_id: data.id, status: data.status });
    setTaskData(data);

    const statusData = await apiCall({
      url: `crm/task-status`,
    });
    setStatusArray(statusData);

    setLoading(false);
  };

  useLayoutEffect(() => {
    let assigneeMatch,
      colabMatch = false;
    if (taskData?.assigned_to) {
      assigneeMatch = taskData?.assigned_to === user.id;
    }
    if (Object.keys(collabList).length > 0) {
      colabMatch = collabList.some((collab) => collab.user_id === user.id);
    }
    setHasMatch(assigneeMatch || colabMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collabList]);

  const handleTimer = async () => {
    setLoadingTimer(true);

    try {
      const startOrStopTimerUrl =
        Object.keys(taskData?.current_time_tracker).length > 0
          ? `crm/stop-timer/${overviewId}`
          : `crm/start-timer/${overviewId}`;

      const response = await axios.post(startOrStopTimerUrl);
      const { data } = response;

      if (data.success === true) {
        const runningTasksData = await axios.get("crm/running-tasks-info");
        const runningTasks = runningTasksData?.data;
        let activeTaskId = "";
        runningTasks?.data?.tasks?.forEach((item) => {
          activeTaskId = item.id;
        });

        if (runningTasks.success === true) {
          let updatedData = {
            ...user,
            running_tasks_count: runningTasks?.data?.count,
            running_task_id: activeTaskId,
          };

          dispatch({ type: "UPDATE", payload: { ...updatedData } });
          if (Object.keys(taskData?.current_time_tracker).length > 0) {
            localStorage.removeItem("runningTaskData");
            addIitemToStore("runningTaskData", {});
          }

          setTimeout(() => {
            handleClose();
          }, 500);
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }

        if (data.data.end_time) {
          // Update task data when stopping the timer
          setTaskData((prevState) => ({
            ...prevState,
            total_time_tracked: data.data.task.total_time_tracked,
            current_time_tracker: [],
            running_status: runningTasks.data.count > 0 ? true : false,
            time_trackers: prevState.time_trackers.map((tracker) => {
              if (tracker.id === data.data.id) {
                tracker.end_time = data.data.end_time;
                tracker.end_time_formatted = data.data.end_time_formatted;
                tracker.time_tracked = data.data.time_tracked;
              }
              return tracker;
            }),
          }));
        } else {
          // Update task data when starting the timer
          setTaskData((prevState) => ({
            ...prevState,
            running_status: runningTasks.data.count > 0 ? true : false,
            current_time_tracker: [
              ...prevState.current_time_tracker,
              data.data,
            ],
            time_trackers: [...prevState.time_trackers, data.data],
            status: data.data.task.status,
            status_formatted: data.data.task.status_formatted,
          }));

          setFormData((prevState) => ({
            ...prevState,
            status: data.data.task.status,
          }));
        }

        setLoadingTimer(false);
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
        setLoadingTimer(false);
      }
    } catch (error) {
      handleTimerError(error);
    }
  };

  const handleTimerError = (error) => {
    if (error.response.data && error.response.data.message) {
      const validation_error =
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.errors
          ? error.response.data.data.errors
          : null;
      showToast(validation_error.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
    setLoadingTimer(false);
  };

  const handleStatusUpdate = (e, status) => {
    e.preventDefault();
    setStatusLoading(true);
    if (status && status !== formData.status) {
      axios({
        method: "post",
        url: `crm/task-status/update`,
        params: { task_id: formData.task_id, status: status },
      })
        .then((response) => {
          if (response.data.success === true) {
            showToast(response.data.message, "success");
            setStatusLoading(false);
            setTaskData(response.data.data);
            setFormData({
              ...formData,
              status: response.data.data.status,
            });
          } else {
            setStatusLoading(false);
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            if ("collaborator_working" in validation_error) {
              showToast(validation_error.collaborator_working[0], "error");
            } else if ("task_id" in validation_error) {
              showToast(validation_error.task_id[0], "error");
            } else if ("status" in validation_error) {
              showToast(validation_error.status[0], "error");
            } else {
              showToast(
                "Something went wrong, please refresh the page and try again.",
                "error"
              );
            }
            setFormData((prevState) => ({
              ...formData,
              status: prevState.status,
            }));
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
          setStatusLoading(false);
        });
    }
  };

  const handleImageClick = (event) => {
    const clickedImageUrl = event.target.src;
    setPreviewImage(clickedImageUrl);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage(null);
  };

  useEffect(() => {
    if (Object.keys(taskData)?.length > 0) {
      const isTaskRunning = user.running_tasks_count > 0;
      setTaskData((prevState) => ({
        ...prevState,
        running_status: isTaskRunning,
        current_time_tracker: isTaskRunning
          ? prevState.current_time_tracker
          : [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleClone = (id) => {
    navigate(
      `${
        renderFrom === "tasks"
          ? `${activeRoute}/overview/${overviewId}/clone/${overviewId}`
          : renderFrom === "sprints"
          ? `${activeRoute}/task/clone/${itemId}`
          : `${activeRoute}/task/clone/${itemId}`
      }?${createSearchParams(allQueryParams)}`
    );
  };

  const getTaskStatusBgColor = (status, type) => {
    const statusWithColor = [
      {
        key: "to_do",
        value: "#d9d2ff",
      },
      {
        key: "in_progress",
        value: "#d8f1ff",
      },
      {
        key: "done",
        value: "#97ff9ab8",
      },
      {
        key: "in_review",
        value: "#ffd2db",
      },
      {
        key: "hold",
        value: "#ffd8a7",
      },
      {
        key: "need_clarification",
        value: "#cecece",
      },
      {
        key: "bug",
        value: "#ffaaaa",
      },
      {
        key: "deployed",
        value: "#b4fffd",
      },
      {
        key: "cancelled",
        value: "#ffbb93",
      },
      {
        key: "reopened",
        value: "#e2ff93",
      },
    ];

    if (status) {
      const foundObject = statusWithColor.find((obj) => obj.key === status);
      return status ? foundObject?.value ?? "grey" : foundObject?.icon;
    } else {
      if (!type) {
        return statusWithColor;
      }
    }
  };

  const handleTicketInfo = (data) => {
    if (permission?.showTicket) {
      setTicketInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const hasPermissionToNotify = (
    loggedInUserId,
    createdUserId,
    assignedUserId,
    taskId
  ) => {
    if (!permission.store) {
      return false;
    }

    const notifiedTasksFromLocalStorage =
      JSON.parse(localStorage.getItem("notifiedTasks")) || [];

    const taskAlreadyNotified = notifiedTasksFromLocalStorage.some(
      (notification) => notification.data === taskId
    );

    if (taskAlreadyNotified) {
      return false;
    }

    return (
      loggedInUserId === createdUserId && loggedInUserId !== assignedUserId
    );
  };

  const handleNotify = (data) => {
    let notifiedTasksFromLocalStorage =
      JSON.parse(localStorage.getItem("notifiedTasks")) || [];

    const taskAlreadyNotified = notifiedTasksFromLocalStorage.some(
      (notification) => notification.data === data?.id
    );

    if (taskAlreadyNotified) {
      showToast("Already notified", "warning");
      return;
    }

    if (!loadingTimer) {
      setNotifyLoading(true);

      axios({
        method: "post",
        url: `crm/notify-task-assignee`,
        data: {
          task_id: data?.id,
          assignee: data?.assigned_to,
        },
      })
        .then(() => {
          showToast("Notification sent successfully", "success");

          const timeStamp = Date.now();
          const newNotification = {
            id: timeStamp,
            data: data?.id,
          };

          notifiedTasksFromLocalStorage.push(newNotification);
          localStorage.setItem(
            "notifiedTasks",
            JSON.stringify(notifiedTasksFromLocalStorage)
          );
        })
        .catch((error) => {
          showToast(error?.response?.data?.message, "error");
        })
        .finally(() => {
          setNotifyLoading(false);
        });
    }
  };

  return !loading ? (
    <>
      <Outlet context={[getData]} />
      <Row className="gx-1" style={{ maxHeight: "100vh" }}>
        {(statusLoading || notifyLoading) && <LoadingOverlay />}
        {Object.keys(taskData)?.length > 0 && (
          <>
            <Col md={9} className="d-flex mb-3">
              <Card className="flex-fill h-100">
                <TimerConfirm
                  show={show}
                  handleClose={handleClose}
                  handleTimer={handleTimer}
                  loading={loadingTimer}
                  timerRunningTask={taskData?.id}
                />
                <DeleteWarning
                  show={deleteModal}
                  id={overviewId}
                  onHide={(status) => handleDeleteClose(status)}
                  setKey={(e) => console.log(e)}
                  heading={"Delete Task"}
                  message={"Are you sure? You want to delete this task!"}
                  type={"TASK"}
                  url="crm/task"
                />
                {labelForm && <LabelsForm setLabelForm={setLabelForm} />}
                <Card.Header className=" border-bottom rounded-0">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <div style={{ marginTop: 3 }}>
                        <BiTask size={25} className="text-dark" />
                      </div>
                      <div
                        className="fs-1 ms-1 mt-1 d-flex flex-column cursor-default"
                        title={`Created At : ${taskData?.created_at ?? "NA"}`}
                      >
                        <span className="text-wrap text-dark">
                          {taskData?.title}
                        </span>
                        <span className="fs--2 text-dark">
                          #{taskData?.task_number}
                          {Translate("Created by")}{" "}
                          <span
                            className="text-primary cursor-pointer"
                            onClick={() =>
                              handleUserProfileArea(taskData?.created_by)
                            }
                          >
                            {taskData?.created_user_full_name}
                          </span>
                          , {taskData?.created_at_diff}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-end text-muted fs--2">
                      {permission?.store && (
                        <CloneButton
                          variant="warning"
                          size="sm"
                          className="me-2 pt-1 pb-1"
                          iconSize={20}
                          onClick={() => handleClone(taskData?.id)}
                        />
                      )}
                      {permission?.update && (
                        <CustomEditButton
                          onClick={() =>
                            navigate(
                              renderFrom === "tasks"
                                ? `/tasks/list/overview/${overviewId}/edit/${overviewId}?${commonSearchParams}`
                                : renderFrom === "sprints"
                                ? `/sprints/info/${itemInfoId}/task/edit/${itemId}?${commonSearchParams}`
                                : `/project/info/${itemInfoId}/task/edit/${itemId}?${commonSearchParams}`
                            )
                          }
                          label="Edit Task"
                        />
                      )}
                      {permission?.delete && (
                        <CustomDeleteButton
                          variant="dark"
                          title="Back"
                          onClick={() => handleDeleteOpen(true)}
                          className={"ms-2"}
                        />
                      )}
                      {taskData?.ticket_id && (
                        <Button
                          variant="falcon-default"
                          size="sm"
                          className="ps-2 ms-2"
                          as={Link}
                          to={`/tickets/list/overview/${taskData?.ticket_id}?${commonSearchParams}`}
                        >
                          <div className="d-flex">
                            <span>
                              <FaEye size={18} />
                            </span>
                            <span className="ms-2">{Translate("Ticket")}</span>
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pt-2">
                  <ListGroup>
                    <ListGroupItem className="border-0 d-flex align-items-center task-overview-content-li">
                      <div className="task-custom-div d-flex flex-column gap-1">
                        <span
                          className="fs--1 d-flex flex-row"
                          style={{ fontWeight: "549" }}
                        >
                          <FaUserGraduate
                            className="me-1"
                            size={17}
                            color="#3FA2F6"
                          />
                          <span className="text-dark d-flex align-items-center gap-1">
                            {Translate("ASSIGNED TO")}
                            {permission?.updateTaskAssignee && (
                              <div style={{ marginTop: "-5px" }}>
                                <UpdateAssigneeListDropDown
                                  url={"crm/task-assignees/update"}
                                  getData={getData}
                                  active={{
                                    label: taskData?.assignee_full_name,
                                    value: taskData?.assigned_to,
                                    image: taskData?.assignee_profile,
                                  }}
                                  itemId={taskData?.id}
                                  method={"post"}
                                  clearable={true}
                                />
                              </div>
                            )}
                            {taskData?.assigned_to &&
                              hasPermissionToNotify(
                                user?.id,
                                taskData?.created_by,
                                taskData?.assigned_to,
                                taskData?.id
                              ) && (
                                <NotifyButton
                                  className="p-1 ms-2 shadow"
                                  variant="light"
                                  onClick={() => handleNotify(taskData)}
                                  iconSize={20}
                                  title="Notify Assignee"
                                />
                              )}
                          </span>
                        </span>
                        {taskData?.assigned_to ? (
                          <div className="d-flex align-items-center">
                            <Image
                              className="me-2"
                              src={taskData?.assignee_profile ?? ProfileImage}
                              roundedCircle
                              alt="?"
                              height={50}
                              width={50}
                              loading="lazy"
                            />

                            <div className="d-flex flex-column">
                              <span className="fs--1 text-dark">
                                {taskData?.assignee_full_name}
                              </span>
                              <span className="fs--2 text-dark">
                                {taskData?.assignee?.roles.map(
                                  (item) => item.name
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <span className="text-dark">
                                {Translate("No Assignee Yet")}
                              </span>
                            </div>
                            <div></div>
                          </>
                        )}
                      </div>
                      {hasMatch && (
                        <div className="ms-auto">
                          <DigitalTimer
                            startTime={
                              taskData?.current_time_tracker[0]?.start_time
                            }
                            handleButtonVisibility={handleTimerSection}
                            loadingTimerButton={loadingTimer}
                            timerRunning={taskData?.running_status}
                          />
                        </div>
                      )}
                    </ListGroupItem>
                    <ListGroupItem className="border-0 task-overview-content-li">
                      <Row className="gy-2">
                        {taskData?.project_id && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <AiOutlineCodeSandbox
                                size={19}
                                color="#FFB22C"
                                style={{ minWidth: "fit-content" }}
                              />
                              <div className="d-flex flex-column ms-1">
                                <span className="fw-bold text-dark">
                                  {Translate("Project")}
                                </span>
                                {permission?.showProject ? (
                                  <Link
                                    className="custom-link-style-crm text-uppercase text-dark"
                                    to={`/project/info/${taskData?.project_id}?tab=overview`}
                                    title={taskData?.project_title}
                                  >
                                    {stringLimiter(taskData?.project_title, 45)}
                                  </Link>
                                ) : (
                                  <span
                                    className="text-dark"
                                    title={taskData?.project_title}
                                  >
                                    {stringLimiter(taskData?.project_title, 45)}
                                  </span>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.sprint_id && (
                          <Col md={3} sm={6}>
                            <div className="d-flex text-capitalize">
                              <BsCalendar2Range
                                size={13}
                                color="#FFA38F"
                                style={{
                                  marginTop: 2,
                                  minWidth: "fit-content",
                                }}
                              />
                              <div className="d-flex flex-column ms-2">
                                <span className="fw-bold text-dark">
                                  {Translate("Sprint")}
                                </span>
                                {taskData?.sprint_id ? (
                                  <>
                                    {permission?.showSprint ? (
                                      <Link
                                        to={`/sprints/info/${
                                          taskData?.sprint_id
                                        }?tab=tasklist&${createSearchParams({
                                          status_filter: JSON.stringify(
                                            defaultTaskStatusFilter
                                          ),
                                        })}`}
                                        className="custom-link-style-crm text-dark"
                                      >
                                        {taskData?.sprint_name}
                                      </Link>
                                    ) : (
                                      <span className="text-dark">
                                        {taskData?.sprint_name}
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  Translate("N/A")
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.start_date_formatted && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <FaRegCalendarPlus
                                size={14}
                                color="#508D4E"
                                style={{
                                  marginTop: 2,
                                  minWidth: "fit-content",
                                }}
                              />
                              <div className="d-flex flex-column ms-1">
                                <span className="fw-bold text-dark">
                                  {Translate("Start Date")}
                                </span>
                                <p className="text-dark">
                                  {taskData?.start_date_formatted
                                    ? taskData?.start_date_formatted
                                    : Translate("N/A")}
                                </p>
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.end_date_formatted && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <FaRegCalendarMinus
                                size={14}
                                color="#FFA38F"
                                style={{
                                  marginTop: 2,
                                  minWidth: "fit-content",
                                }}
                              />
                              <div className="d-flex flex-column ms-1">
                                <span className="fw-bold text-dark">
                                  {Translate("End Date")}
                                </span>
                                <p className="text-dark">
                                  {taskData?.end_date_formatted
                                    ? taskData?.end_date_formatted
                                    : Translate("N/A")}
                                </p>
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.total_time_tracked && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <IoMdTime
                                size={17}
                                color="#508D4E"
                                style={{ minWidth: "fit-content" }}
                              />
                              <div className="d-flex flex-column ms-1">
                                <span className="fw-bold text-dark">
                                  {Translate("Total Time Spent")}
                                </span>
                                <p className="text-dark">
                                  {taskData?.total_time_tracked}
                                </p>
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.priority && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <MdOutlinePriorityHigh
                                size={15}
                                color="#E4003A"
                                style={{
                                  marginTop: 2,
                                  minWidth: "fit-content",
                                }}
                              />
                              <div className="d-flex flex-column">
                                <span className="fw-bold text-dark">
                                  {Translate("Priority")}
                                </span>
                                <div>
                                  <span>
                                    {getPriorityWithProps(
                                      taskData?.priority,
                                      "icon",
                                      20
                                    )}
                                  </span>
                                  <span className="ms-1 text-dark">
                                    {taskData?.priority
                                      ? taskData?.priority
                                      : "N/A"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.ticket && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <BiSupport
                                size={15}
                                color="#E4003A"
                                className="me-1"
                                style={{
                                  marginTop: 2,
                                  minWidth: "fit-content",
                                }}
                              />
                              <div className="d-flex flex-column">
                                <div className="d-flex gap-1">
                                  <span className="fw-bold text-dark">
                                    {Translate("Ticket")}
                                  </span>
                                  <div
                                    onClick={() => {
                                      handleTicketInfo(taskData?.ticket);
                                    }}
                                  >
                                    <RedirectButton />
                                  </div>
                                </div>
                                <Badge
                                  bg="danger"
                                  className="fw-bold cursor-pointer"
                                  style={{ fontSize: "11px" }}
                                >
                                  {taskData?.ticket?.reference}
                                </Badge>
                              </div>
                            </div>
                          </Col>
                        )}
                        {taskData?.product_name && (
                          <Col md={3} sm={6}>
                            <div className="d-flex">
                              <FiPackage
                                size={17}
                                color="#29C3BE"
                                style={{
                                  marginTop: 2,
                                  minWidth: "fit-content",
                                }}
                              />
                              <div className="d-flex flex-column ms-1">
                                <span className="fw-bold text-dark">
                                  {Translate("Product")}
                                </span>
                                <p className="text-dark">
                                  {taskData?.product_name}
                                </p>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </ListGroupItem>
                    {taskData?.description && (
                      <ListGroupItem className="border-0 task-overview-content-li">
                        <div className="task-custom-div d-flex">
                          <MdDescription
                            size={16}
                            color="#E4003A"
                            style={{ marginTop: 1, minWidth: "fit-content" }}
                          />
                          <div className="d-flex flex-column ms-1">
                            <span className="fw-bold text-dark">
                              {Translate("Description")}
                            </span>
                            <div>
                              {showPreview && previewImage && (
                                <ImagePreview
                                  previewImage={previewImage}
                                  handleClosePreview={handleClosePreview}
                                />
                              )}
                              <ShowHtmlContent
                                description={taskData?.description}
                                handleImageClick={handleImageClick}
                              />
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    )}
                    <ListGroupItem className="border-0 task-overview-content-li">
                      <div className="d-flex">
                        <HiOutlineStatusOnline
                          size={17}
                          color="#B692C2"
                          style={{ marginTop: 1, minWidth: "fit-content" }}
                        />
                        <div className="d-flex flex-column ms-1">
                          <span className="fw-bold text-dark mb-1">Status</span>
                          <ListGroup
                            horizontal
                            className="task-overview-status-list-group d-flex flex-wrap gap-2"
                          >
                            {statusArray.map((status, key) => (
                              <ListGroup.Item
                                key={key}
                                as={
                                  permission?.updateTaskStatus ? Button : "div"
                                }
                                className={`task-overview-status-list-item custom-button-task-status ${
                                  formData.status === status ? "active" : ""
                                } ${
                                  !permission?.updateTaskStatus
                                    ? "rounded-0"
                                    : ""
                                }`}
                                style={{
                                  border: 1,
                                  borderColor: getTaskStatusWithProps(status),
                                  borderStyle: "solid",
                                  backgroundColor:
                                    (formData.status === status ||
                                      hoveredStatus === status) &&
                                    getTaskStatusBgColor(status),
                                }}
                                onClick={(e) => {
                                  permission?.updateTaskStatus &&
                                    handleStatusUpdate(e, status);
                                }}
                                onMouseEnter={() => setHoveredStatus(status)}
                                onMouseLeave={() => setHoveredStatus(false)}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="d-flex align-items-center">
                                    {getTaskStatusWithProps(status, "icon", 17)}
                                    <div className="text-capitalize fs--1  text-dark ms-1">
                                      {status.replace("_", " ")}
                                    </div>
                                  </div>

                                  {formData.status === status && (
                                    <TbMapPinFilled
                                      className="ms-1 task-overview-status-icon"
                                      size={18}
                                    />
                                  )}
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 task-overview-content-li">
                      <Tabs
                        defaultActiveKey={activeTab ? activeTab : "comments"}
                        className="mb-2 mt-2 custom-lead-offcanvas-header-tabs"
                        variant="tabs"
                        onSelect={(tabname) => {
                          navigate(
                            `?${createSearchParams({
                              ...allQueryParams,
                              info_tab: tabname,
                            })}`
                          );
                        }}
                      >
                        <Tab
                          className="pb-3 task-overview-content-tab"
                          eventKey="comments"
                          title={
                            <div className="d-flex align-items-center">
                              <BiComment size={14} color="#d68c45" />
                              <span className="ms-1">
                                {Translate("Comments")}
                              </span>
                            </div>
                          }
                        >
                          <Comments comment_type="task" id={overviewId} />
                        </Tab>
                        <Tab
                          className="pb-3 task-overview-content-tab"
                          eventKey="attachment"
                          title={
                            <div className="d-flex align-items-center">
                              <MdAttachFile size={14} color="#3FA2F6" />{" "}
                              <span className="ms-1">
                                {Translate("Attachment")}
                              </span>
                            </div>
                          }
                        >
                          {activeTab === "attachment" && (
                            <CommonAttachments
                              itemId={overviewId}
                              type={"TASK"}
                            />
                          )}
                        </Tab>
                        {taskData?.time_trackers.length > 0 && (
                          <Tab
                            className="pb-3 task-overview-content-tab"
                            eventKey="time_sheet"
                            title={
                              <div className="d-flex align-items-center">
                                <GrDocumentTime size={14} color="#52b788" />
                                <span className="ms-1">
                                  {Translate("Timesheet")}
                                </span>
                              </div>
                            }
                          >
                            {activeTab === "time_sheet" && (
                              <TimeSheetLog infoData={taskData} />
                            )}
                          </Tab>
                        )}
                        <Tab
                          className="pb-3 task-overview-content-tab"
                          eventKey="remainder"
                          title={
                            <div className="d-flex align-items-center">
                              <TbClockBolt size={14} color="#FFA38F" />
                              <span className="ms-1">
                                {Translate("Reminder")}
                              </span>
                            </div>
                          }
                        >
                          {activeTab === "remainder" && (
                            <Reminders itemId={overviewId} type="task" />
                          )}
                        </Tab>
                        <Tab
                          className="pb-3 task-overview-content-tab"
                          eventKey="activity"
                          title={
                            <div className="d-flex align-items-center">
                              <RxActivityLog size={14} color="#B692C2" />
                              <span className="ms-1">
                                {Translate("Activity Log")}
                              </span>
                            </div>
                          }
                        >
                          <Col md={8}>
                            {activeTab === "activity" && (
                              <ActivityTimeLine itemId={taskData?.id} />
                            )}
                          </Col>
                        </Tab>
                      </Tabs>
                    </ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-3">
              <Row>
                <Col md={12}>
                  <Card className="h-100">
                    <Card.Header className="fs-1 pb-0 text-dark">
                      <AiOutlineNodeCollapse className="me-1" color="#E4003A" />{" "}
                      {Translate("Collaborators")}
                    </Card.Header>
                    <Card.Body>
                      <Collaborator
                        scroll={true}
                        taskId={overviewId}
                        assignee_id={taskData?.assigned_to}
                        setCollabList={setCollabList} //for collab list for return
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={12}>
                  <Card className="">
                    <Card.Header className="fs-1 pb-0 d-flex">
                      <div className="text-dark">
                        <BiLabel className="me-1" color="#FFA38F" />{" "}
                        {Translate("Labels")}
                      </div>
                      <div className="ms-auto">
                        <Button
                          size="sm"
                          variant="transparent"
                          className="p-0 custom-transperent-common-button"
                          onClick={() => {
                            setLabelForm(!labelForm);
                          }}
                          title={"Create New Label"}
                        >
                          <MdOutlineNewLabel size={20} color="#88D66C" />
                        </Button>
                        <Button
                          size="sm"
                          variant="transparent"
                          className="p-0 custom-transperent-common-button ms-2"
                          onClick={() => {
                            setReloadLabels(!reloadLabels);
                          }}
                          title={"Refresh"}
                        >
                          <TbRefreshDot size={20} color="#433D8B" />
                        </Button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Labels taskiId={overviewId} refresh={reloadLabels} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {hasNonZeroValue && (
                <Row className="mt-1">
                  <Col md={12}>
                    <Card className="">
                      <Card.Header className="fs-1 pb-0">
                        <BiDoughnutChart className="me-1" size={25} />
                        {Translate("Task Contribution")}{" "}
                        <span className="fs--1">(%)</span>
                      </Card.Header>
                      <Card.Body className="d-flex justify-content-center ">
                        <DoughnutChart
                          height={150}
                          width={150}
                          data={dataDoughnut}
                          options={optionsDoughnut}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}

              <Row className="mt-1">
                <Col md={12}>
                  <Card className="">
                    <Card.Header className="fs-1 pb-0 text-dark">
                      <MdOutlineAutoGraph
                        className="me-1"
                        size={25}
                        color="#3FA2F6"
                      />
                      {Translate("Task Engagement")}{" "}
                      <span className="fs--1">(Hours)</span>
                    </Card.Header>
                    <Card.Body
                      style={{ width: "100%", height: "250px" }}
                      className="p-0 pie-chart-container"
                    >
                      <PieChart
                        options={options}
                        height={"100%"}
                        width={"100%"}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
      <UserProfile
        show={showUserProfileArea}
        handleClose={() => setShowUserProfileArea(false)}
        id={userProfile}
      />
      <TicketInfo
        show={ticketInfo?.show}
        id={ticketInfo?.id}
        onHide={() => {
          setTicketInfo({
            show: false,
            id: "",
          });
        }}
      />
    </>
  ) : (
    <Card className="mt-3 mb-3">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "89vh" }}
      >
        <LoadingCommon loadingText="Loading Task Overview" />
      </div>
    </Card>
  );
};

export default TaskOverview;
