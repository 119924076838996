import { React, useEffect, useState } from "react";
import { Offcanvas, Tab, Tabs, Row, Col, Card } from "react-bootstrap";
import { FaCalendarAlt, FaUser, FaUserGraduate } from "react-icons/fa";
import useAxisproPermission from "hooks/useAxisproPermission";
import axios from "axios";
import { MdAttachFile, MdOutlineIncompleteCircle } from "react-icons/md";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { RxActivityLog } from "react-icons/rx";
import {
  CustomBackButton,
  CustomEditButton,
} from "module/common/Buttons/AppButtons";
import LeadStage from "module/Leads/Lead/LeadInfo/Overview/LeadStage";
import Reminders from "module/common/ViewPages/Common/Reminders/Reminders";
import { RiTeamFill } from "react-icons/ri";
import ListLoading from "module/common/ListLoading";
import TabLoading from "module/common/TabLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";

function TodoInfo({ show, id, onHide }) {
  const navigate = useNavigate();
  const [key, setKey] = useState("remainder");
  const [todoData, setTodoData] = useState({});
  const [loading, setLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const Translate = useAxisproTranslate();
  const fetchData = async () => {
    setLoading(true);
    axios
      .get(`crm/crm-todo/${id}`)
      .then((res) => {
        if (res.data.success) {
          setTodoData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id && show) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, show]);

  const handleEdit = () => {
    navigate(
      `edit/${id}?${createSearchParams({
        ...allQueryParams,
      })}`
    );
    onHide();
  };

  return (
    <Offcanvas
      show={show}
      onHide={onHide}
      placement="end"
      style={{
        width: "600px",
      }}
    >
      <Offcanvas.Header className="border-bottom">
        <Offcanvas.Title className="d-flex flex-wrap justify-content-between align-items-center gap-2 w-100">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <FontAwesomeIcon icon={"list-check"} size="lg" />
            <span className="fs-1">{Translate("Todo Overview")}</span>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-start gap-2">
            {axisProPermission("update-lead") && (
              <CustomEditButton
                variant="light"
                onClick={() => {
                  handleEdit();
                }}
                label="Edit Todo"
                className="shadow"
              />
            )}
            <CustomBackButton
              variant="light"
              title="Back"
              onClick={onHide}
              className={"shadow"}
            />
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        {Object.keys(todoData).length > 0 && !loading ? (
          <>
            <div>
              <div
                className={`p-3 border-bottom`}
                style={{ backgroundColor: "#eff0fd" }}
              >
                <Card>
                  <Card.Header className="pb-1" style={{ fontSize: "17px" }}>
                    {todoData?.title}
                  </Card.Header>
                  <Card.Body>
                    {todoData?.description && (
                      <Row className="border-bottom mb-2">
                        <h6 className="lead-information text-dark">
                          {todoData?.description}
                        </h6>
                      </Row>
                    )}
                    <Row className="gy-3">
                      <Col xs={6} md={4}>
                        <LeadStage
                          data={todoData.status.replace("_", " ")}
                          label={"Status"}
                          icon={
                            <MdOutlineIncompleteCircle
                              size={17}
                              className="me-2 text-mute"
                              color="#0A6847"
                            />
                          }
                          className="text-uppercase"
                        />
                      </Col>
                      <Col xs={6} md={4}>
                        <LeadStage
                          data={todoData.assigned_to_user_full_name}
                          label={"Assigned To"}
                          icon={
                            <FaUserGraduate
                              size={17}
                              className="me-2 text-mute"
                              color="#3FA2F6"
                            />
                          }
                        />
                      </Col>
                      <Col xs={6} md={4}>
                        <LeadStage
                          data={todoData.created_user_full_name}
                          label={"Created By"}
                          icon={
                            <FaUser
                              size={17}
                              className="me-2 text-mute"
                              color="#E90074"
                            />
                          }
                        />
                      </Col>
                      {todoData?.team?.name && (
                        <Col xs={6} md={4} className="mt-0">
                          <LeadStage
                            data={todoData?.team?.name}
                            label={"Team"}
                            icon={
                              <RiTeamFill
                                size={17}
                                className="me-2 text-mute"
                                color="#FFA38F"
                              />
                            }
                          />
                        </Col>
                      )}
                      {todoData.due_date_formatted && (
                        <Col className="mt-0" xs={6} md={4}>
                          <LeadStage
                            data={todoData.due_date_formatted}
                            label={"Due Date"}
                            icon={
                              <FaCalendarAlt
                                size={17}
                                className="me-2 text-mute"
                                color="#E4003A"
                              />
                            }
                          />
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <Tabs
              defaultActiveKey="remainder"
              className="custom-lead-offcanvas-header-tabs mt-2"
              variant="tabs"
              justify
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="remainder"
                title={
                  <div className="d-flex align-items-center">
                    <MdAttachFile size={14} color="#3FA2F6" />{" "}
                    <span className="ms-1">Reminder</span>
                  </div>
                }
              >
                {key === "remainder" ? (
                  <Reminders itemId={id} type="todo" />
                ) : null}
              </Tab>
              <Tab
                eventKey="activity"
                title={
                  <div className="d-flex align-items-center">
                    <RxActivityLog size={12} color="#B692C2" />{" "}
                    <span className="ms-2">Activity</span>
                  </div>
                }
              >
                {key === "activity" ? <ActivityTimeLine itemId={id} /> : null}
              </Tab>
            </Tabs>
          </>
        ) : loading ? (
          <>
            <TabLoading />
            <ListLoading />
          </>
        ) : (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <span>No Todo Data Available</span>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default TodoInfo;
