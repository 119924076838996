import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";

function LeadStage({ data, label, icon, className = "" }) {
  const Translate = useAxisproTranslate();
  return (
    <Container fluid className="p-2">
      <div className="d-flex">
        <div className="text-secondary">{icon}</div>
        <div>
          <span
            className="lead-information text-center"
            style={{ color: "black" }}
          >
            {Translate(label)} :
          </span>
          <h6 className={`lead-information text-dark ${className}`}>
            {data ? data : "N/A"}
          </h6>
        </div>
      </div>
    </Container>
  );
}

LeadStage.propTypes = {
  data: PropTypes.any,
  label: PropTypes.string,
  icon: PropTypes.any,
};

export default LeadStage;
