import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Badge, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { BsCalendar2Range } from "react-icons/bs";
import { apiCall } from "helpers/apiCalls";
import { MODULES } from "constants/Constants";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { commonSprintFilterFormKeys } from "helpers/formKeys";
import { DeleteContext, StoreContext } from "context/Context";
import {
  getObjectFromLocalStorage,
  setObjectInLocalStorage,
} from "helpers/utils.js";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import removeRefData from "helpers/removeRefData";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import CommonFilter from "components/filter/CommonFilter";
import removeEmptyFields from "helpers/removeEmptyFields";
import RefreshButton from "components/refresh-button/RefreshButton";
import TableHeader from "helpers/tableHeader";
import TableOverlayTrigger from "components/table-overlay-trigger/TableOverlayTrigger";
import CommonStatusUpdateDropDown from "components/common/DropDown/CommonStatusUpdateDropDown";

const SprintTable = ({ projectId, renderFrom }) => {
  let navigate = useNavigate();
  const { itemInfoId } = useParams();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const { addIitemToStore } = useContext(StoreContext);
  const Translate = useAxisproTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [sprintsList, setSprintsList] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { from_date, to_date, date_range_for, project_id, name, status } =
    allQueryParams;
  const userBasedFilterVisibility =
    getObjectFromLocalStorage("filter")?.["sprintFilter"] ?? false;
  const [filterVisibility, setFilterVisibility] = useState(
    userBasedFilterVisibility
  );
  const permission = {
    show: GetAcodaxPermission("SPRINT", "show"),
    delete: GetAcodaxPermission("SPRINT", "delete"),
    update: GetAcodaxPermission("SPRINT", "update"),
    store: GetAcodaxPermission("SPRINT", "store"),
    update_status: GetAcodaxPermission("SPRINT_STATUS", "update"),
  };
  const actionsPermission = permission?.update || permission?.delete;

  const preApplingFilter = {
    status_filter: defaultTaskStatusFilter.join("/"),
    status_filter_ref: JSON.stringify(
      defaultTaskStatusFilter.map((item) => ({
        label: item?.replaceAll("_", " "),
        value: item,
      }))
    ),
    tab: "tasklist",
    page: 1,
    sprint_id: renderFrom === MODULES.PROJECT ? `routeId` : "",
    sprint_id_ref:
      renderFrom === MODULES.PROJECT
        ? JSON.stringify({
            value: `routeId`,
            label: name,
          })
        : "",
  };

  const commonRouteTo = `/${
    renderFrom === MODULES.PROJECT
      ? `project/info/${projectId}`
      : `sprints/info/routeId`
  }?${createSearchParams({
    ...removeEmptyFields(preApplingFilter),
  })}`;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/sprint/" + id,
        title: "Delete Sprint",
        message: "Are you sure you want to delete this sprint?",
        onSuccess: () => {
          const dataWithoutDeletedItem = sprintsList.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/sprints?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setSprintsList((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  useEffect(() => {
    if (
      allQueryParams?.project_id ||
      allQueryParams?.status_ref ||
      allQueryParams?.from_date ||
      allQueryParams?.to_date ||
      allQueryParams?.project_id_ref ||
      allQueryParams?.date_range ||
      allQueryParams?.date_range_for ||
      allQueryParams?.name ||
      allQueryParams?.status
    ) {
      addIitemToStore("sprintParams", allQueryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allQueryParams?.page,
    allQueryParams?.project_id,
    allQueryParams?.status_ref,
    allQueryParams?.from_date,
    allQueryParams?.to_date,
    allQueryParams?.project_id_ref,
    allQueryParams?.date_range,
    allQueryParams?.date_range_for,
    allQueryParams?.name,
    allQueryParams?.status,
  ]);

  const statusFilter = status;
  const afterStatusChange = (status, id, oldStatus) => {
    if (sprintsList?.data?.length > 0 && status) {
      const statusArray = statusFilter ? statusFilter?.split("/") : [];
      setSprintsList((prevData) => {
        const updatedItems = prevData.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              status: status,
              status_formatted: status,
              old_status: oldStatus ?? "",
            };
          } else {
            return { ...item };
          }
        });
        let filteredItems = [];
        if (statusArray?.length > 0) {
          filteredItems = updatedItems.filter((item) =>
            statusArray.includes(item.status)
          );
        } else {
          filteredItems = updatedItems;
        }
        return {
          ...prevData,
          data: filteredItems,
          meta: {
            ...prevData.meta,
            to:
              prevData?.data?.length > filteredItems?.length
                ? prevData?.meta?.to - 1
                : prevData?.meta?.to,
            total:
              prevData?.data?.length > filteredItems?.length
                ? prevData?.meta?.total - 1
                : prevData?.meta?.total,
          },
        };
      });
    }
  };

  const columns = [
    {
      accessor: "reference",
      Header: Translate("REFERENCE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      routeTo: permission?.show && commonRouteTo,
      Cell: (rowData) => {
        const { reference } = rowData.row.original;

        return (
          <>
            {reference ? (
              <Badge className="fs--2" bg="info" style={{ minWidth: "8rem" }}>
                {reference}
              </Badge>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      accessor: "name",
      Header: Translate("SPRINT NAME"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "20rem" },
      },
      routeTo: permission?.show && commonRouteTo,
      Cell: (rowData) => {
        const { name, id } = rowData.row.original;
        return <TableOverlayTrigger content={name} id={id} length={40} />;
      },
    },
    {
      accessor: "description",
      Header: Translate("DESCRIPTION"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "17rem" },
      },
      routeTo: permission?.show && commonRouteTo,
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return (
          <TableOverlayTrigger content={description} id={id} length={33} />
        );
      },
    },
    {
      accessor: "project_title",
      Header: Translate("PROJECT"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: { minWidth: "12rem" },
      },
      routeTo: permission?.show && commonRouteTo,
      Cell: (rowData) => {
        const { project_title, id } = rowData.row.original;
        return (
          <TableOverlayTrigger content={project_title} id={id} length={20} />
        );
      },
    },
    {
      accessor: "start_date_time_formatted",
      Header: Translate("START AT"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: {
          minWidth: "9rem",
        },
      },
      routeTo: permission?.show && commonRouteTo,
      Cell: (rowData) => {
        const { start_date_time_formatted } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {start_date_time_formatted ? start_date_time_formatted : "-"}
          </h5>
        );
      },
    },
    {
      accessor: "end_date_time_formatted",
      Header: Translate("END AT"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
        style: {
          minWidth: "9rem",
        },
      },
      routeTo: permission?.show && commonRouteTo,
      Cell: (rowData) => {
        const { end_date_time_formatted } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {end_date_time_formatted ? end_date_time_formatted : "-"}
          </h5>
        );
      },
    },
    {
      accessor: "status",
      Header: Translate("STATUS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-0",
      },
      Cell: (rowData) => {
        const { status, id } = rowData.row.original;
        return (
          <>
            {permission?.update_status ? (
              <CommonStatusUpdateDropDown
                afterStatusChange={afterStatusChange}
                activeStatus={status}
                postParams={{ sprint_id: id }}
                fetchUrl="crm/list-sprint-status"
                method="post"
                postUrl="/crm/sprint-status/update"
                width="130px"
                disableAfterEffects
                oldStatus={status}
              />
            ) : (
              <h5 className="mb-0 fs--1">{status || "-"}</h5>
            )}
          </>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="major-div-element">
            {permission?.update && (
              <EditButton
                route={
                  projectId
                    ? `/project/info/${projectId}/sprints/edit/${id}?${createSearchParams(
                        {
                          ...allQueryParams,
                        }
                      )}`
                    : `/sprints/edit/${id}?${createSearchParams({
                        ...allQueryParams,
                      })}`
                }
              />
            )}
            {permission?.delete && (
              <DeleteButton
                className={"ms-1"}
                onClick={() => handleDelete(id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const getApiData = async (page) => {
    const statusFilterTemp = status ? status.split("/") : [];
    const dynamicFilterTemp = filterItems
      ? removeRefData(createFilterArray(filterItems), "array")
      : null;

    const filterParamObject = {
      filters: dynamicFilterTemp,
      project_id: projectId ? projectId : project_id ? project_id : "",
      page: page,
      from_date: from_date,
      to_date: to_date,
      date_range_for: date_range_for,
      name: name,
      status: statusFilterTemp,
    };

    const data = await apiCall({
      url: "crm/sprint",
      params: filterParamObject,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return data;
  };

  const getData = async (page = 1) => {
    setIsLoading(true);
    try {
      let data = await getApiData(page);
      if (data?.data?.length < 1 && pageNumber > 1) {
        data = await getApiData(pageNumber - 1);
      }
      setSprintsList(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!itemInfoId || projectId) {
      getData(pageNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    from_date,
    to_date,
    date_range_for,
    project_id,
    name,
    status,
  ]);

  useEffect(() => {
    const existingFilter = getObjectFromLocalStorage("filter") || {};
    const updatedFilter = {
      ...existingFilter,
      sprintFilter: filterVisibility,
    };

    setObjectInLocalStorage("filter", updatedFilter);
  }, [filterVisibility]);

  return (
    <>
      <Outlet context={[getData]} />
      {!itemInfoId || projectId ? (
        <>
          <Card className={`flex-fill ${!renderFrom ? "mb-3" : "shadow-none"}`}>
            <Card.Header
              className={`d-flex flex-row justify-content-between align-items-center flex-wrap gap-2 ${
                !filterVisibility ? "border-bottom" : ""
              }`}
            >
              <div className="d-flex align-items-center">
                <BsCalendar2Range
                  size={!renderFrom ? 15 : 16}
                  className="me-2 text-dark"
                />
                <TableHeader title={Translate("SPRINTS LIST")} table />
              </div>
              <div className="d-flex flex-wrap gap-1">
                <RefreshButton
                  style={{ fontSize: "11px" }}
                  loading={isLoading}
                  className={"d-flex align-items-center justify-content-center"}
                  variant={"info"}
                  onClick={() => getData(pageNumber)}
                />
                {permission?.store && (
                  <AddButton
                    to={
                      projectId
                        ? `/project/info/${projectId}/sprints/add?${createSearchParams(
                            {
                              ...allQueryParams,
                            }
                          )}`
                        : `/sprints/add?${createSearchParams({
                            ...allQueryParams,
                          })}`
                    }
                    title="Add Sprint"
                  />
                )}
                <FilterCommonButton
                  filterVisibility={filterVisibility}
                  setFilterVisibility={setFilterVisibility}
                />
              </div>
            </Card.Header>
            {filterVisibility && (
              <CommonFilter
                filterKeys={commonSprintFilterFormKeys}
                module="sprints"
                additionalResetParams={
                  renderFrom === MODULES.PROJECT ? { tab: "sprintlist" } : {}
                }
              />
            )}
            {!isLoading && sprintsList?.data ? (
              <>
                <SimpleBar
                  className={
                    !filterVisibility
                      ? renderFrom
                        ? "simplebar-style-with-pagination-for-tab-in-project simplebar-custom"
                        : "simplebar-style-with-pagination simplebar-custom"
                      : renderFrom
                      ? "simplebar-style-with-pagination-for-tab-in-project-filter-on simplebar-custom"
                      : "simplebar-style-with-pagination-and-filter-on simplebar-custom"
                  }
                >
                  <Card.Body className="p-0">
                    <AdvanceTableWrapper
                      columns={columns}
                      data={sprintsList.data}
                      sortable
                    >
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle position-sticky top-0 z-1"
                        rowClassName="align-middle white-space-nowrap"
                        tablebodyClassName="custom-table-body position-sticky"
                        tableprops={{
                          size: "sm",
                          striped: true,
                          className: "fs--1 mb-0 overflow-hidden",
                        }}
                      />
                    </AdvanceTableWrapper>
                  </Card.Body>
                </SimpleBar>
                <Card.Footer className="border-top p-2 footer">
                  {sprintsList?.links && (
                    <AppPagination
                      data={sprintsList}
                      url="/sprints"
                      search={allQueryParams}
                    />
                  )}
                </Card.Footer>
              </>
            ) : (
              <LoadingScreenCover
                style={{ height: renderFrom ? "50vh" : "" }}
                className={`flex-fill ${!renderFrom ? "" : "shadow-none"}`}
              >
                <LoadingCommon loadingText="Looking for Sprints" />
              </LoadingScreenCover>
            )}
          </Card>
        </>
      ) : null}
    </>
  );
};

export default SprintTable;
