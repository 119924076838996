import React from "react";
import Select from "react-select";

const CommonSelect = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  options,
  isClearable = true,
}) => {
  return (
    <Select
      defaultOptions
      isClearable={isClearable}
      options={options}
      name={name ? name : "project"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={`custom-select ${
        error
          ? "form-control ps-0 py-0 is-invalid text-capitalize"
          : "text-capitalize"
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
            ? state.isSelected
              ? "#192E49"
              : "#e1e2ea"
            : state.isSelected
            ? "#696c96"
            : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

export default CommonSelect;
