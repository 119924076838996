import { React, useState } from "react";
import AsyncSelect from "react-select/async";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerDetails from "components/customer-details/CustomerDetails";
import Invitation from "module/common/Invitation/Invitation";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";

const SelectCustomer = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  showCustomerDetails,
  style,
  placeholder,
  isDisabled = false,
  onFocus,
  clearable = true,
}) => {
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showInvitation, setInvitation] = useState(false);

  const handleInvite = () => setInvitation(!showInvitation);
  const portalTarget = document.createElement("div");
  document.body.appendChild(portalTarget);

  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: "sales/customerAutocomplete",
      setParams: (searchKey) => ({ name: searchKey }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
    });

  const setOptionData = (option) => ({
    label: option.name,
    value: keyName ? option[keyName] : option.id,
  });

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const NoOptionsMessage = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span>No options</span>
        <Button
          variant="transparant"
          className="p-0 custom-transperent-common-button"
          onClick={handleInvite}
          size="sm"
        >
          <span className="text-primary">Invite</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      <AsyncSelect
        isDisabled={isDisabled}
        cacheOptions
        loadOptions={fetchData}
        onFocus={setDefaultResult}
        menuPortalTarget={portalTarget}
        defaultOptions={defaultOptions}
        isLoading={loading}
        isClearable={clearable}
        name={name ? name : "Client"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error && "form-control ps-0 py-0 is-invalid"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            fontSize: "13px",
            borderRadius: "0px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? state.isSelected
                ? "#192E49"
                : "#e1e2ea"
              : state.isSelected
              ? "#696c96"
              : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            ...style,
          }),
        }}
        noOptionsMessage={NoOptionsMessage}
      />
      {showCustomerDetails && value && (
        <>
          <Button
            className="d-flex flex-row mt-2 align-items-center p-0"
            variant="white"
            onClick={handleCustomerDetailsArea}
          >
            <FontAwesomeIcon icon="user-circle" size="xs" className="me-1" />
            <span className="small text-primary">View Client Details</span>
          </Button>
          <CustomerDetails
            show={showCustomerDetailsArea}
            onHide={handleCustomerDetailsArea}
            value={value}
            showCustomerDetailsArea={showCustomerDetailsArea}
          />
        </>
      )}
      <Invitation showInvitation={showInvitation} handleClose={handleInvite} />
    </>
  );
};

SelectCustomer.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  showCustomerDetails: PropTypes.bool,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};

export default SelectCustomer;
