import React, { useEffect, useRef, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createSearchParams, useNavigate } from "react-router-dom";
import { showToast } from "module/common/Toast/toast";
import axios from "axios";
import ListLoading from "module/common/ListLoading";
import { GetAcodaxPermission } from "module/common/Permissions/AcodaxPermission";
import {
  CustomBackButton,
  CustomSearchButton,
  RedirectButton,
} from "module/common/Buttons/AppButtons";
import SimpleBar from "simplebar-react";
import LeadOffcanvas from "module/Leads/Lead/LeadInfo/LeadOffcanvas";
import TodoInfo from "module/Todo/TodoInfo";
import ProjectOffcanvas from "module/Project/ProjectOffcanvas/ProjectOffcanvas";
import TicketInfo from "module/Tickets/TicketInfo/TicketInfo";
import TaskInfo from "module/Task/TaskInfo/TaskInfo";
import { SiGoogleadmob } from "react-icons/si";
import { FaUsers, FaUserTie } from "react-icons/fa";
import { TbBulbFilled } from "react-icons/tb";
import { AiOutlineCodeSandbox, AiOutlineFileSearch } from "react-icons/ai";
import { BiSupport, BiTask } from "react-icons/bi";
import { IoMdContact, IoMdPeople } from "react-icons/io";

const SearchBox = () => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [data, setData] = useState([]);
  const apiCallDelayRef = useRef(null);
  const searchInputRef = useRef(null);
  const formattedFieldsRef = useRef([]);
  const [leadInfo, setLeadInfo] = useState({ show: false, id: "" });
  const [todoInfo, setTodoInfo] = useState({ show: false, id: "" });
  const [projectInfo, setProjectInfo] = useState({ show: false, id: "" });
  const [ticketInfo, setTicketInfo] = useState({ show: false, id: "" });
  const [taskInfo, setTaskInfo] = useState({ show: false, id: "" });
  const permission = {
    showLead: GetAcodaxPermission("LEAD", "view"),
    showTodo: GetAcodaxPermission("TODO", "show"),
    showCustomer: GetAcodaxPermission("ACCOUNT", "show"),
    showOpportunity: GetAcodaxPermission("OPPORTUNITY", "show"),
    showEmployee: GetAcodaxPermission("EMPLOYEE", "show"),
    showProject: GetAcodaxPermission("PROJECT", "show"),
    showTicket: GetAcodaxPermission("TICKET", "show"),
    showTask: GetAcodaxPermission("TASK", "show"),
  };

  const handleSearch = () => {
    if (searchInputValue.trim().length >= 3) {
      fetchData(searchInputValue);
      setIsOpen(true);
    } else {
      showToast("Please enter at least 3 characters.", "error");
    }
  };

  const handleNavigate = () => {
    navigate(
      `search-result?${createSearchParams({
        searchWord: searchInputValue,
      })}`
    );
    setIsOpen(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setIsOpen(true);
      handleSearch();
    }
  };

  const getOptions = (inputValue) =>
    new Promise((resolve, reject) => {
      try {
        if (apiCallDelayRef?.current) clearTimeout(apiCallDelayRef.current);
        setDataProcessing(true);
        setData([]);
        apiCallDelayRef.current = setTimeout(
          async () => {
            const response = await axios.get("crm/search", {
              params: {
                search_term: inputValue,
              },
            });
            let result = response?.data?.data;
            resolve(result);
            setDataProcessing(false);
          },
          inputValue?.length > 0 ? 1200 : 0
        );
      } catch (error) {
        console.error("error", error);
        reject(error);
        setDataProcessing(false);
      }
    });

  const fetchData = async (inputValue) => {
    formattedFieldsRef.current = [];
    setData([]);
    const options = await getOptions(inputValue);
    setData(options);
  };

  const handleLeadInfoPage = (data) => {
    if (permission?.showLead) {
      setLeadInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const handleTodoInfoPage = (data) => {
    if (permission?.showTodo) {
      setTodoInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const handleProjectInfo = (data) => {
    if (permission?.showProject) {
      setProjectInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const handleTicketInfo = (data) => {
    if (permission?.showTicket) {
      setTicketInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const handleTaskInfo = (data) => {
    if (permission?.showTask) {
      setTaskInfo({
        show: true,
        id: data?.id,
      });
    }
  };

  const handleRowClick = (type, item) => {
    switch (type) {
      case "lead":
        handleLeadInfoPage(item);
        setIsOpen(false);
        break;

      case "todo":
        handleTodoInfoPage(item);
        setIsOpen(false);
        break;

      case "customer":
        if (permission?.showCustomer) {
          navigate(
            `/account/overview/${item?.id}?${createSearchParams({
              tab: "overview",
            })}`
          );
          setIsOpen(false);
        }
        break;

      case "employee":
        if (permission?.showEmployee) {
          navigate(
            `/employee/overview/${item?.id}?${createSearchParams({
              tab: "gi",
            })}`
          );
          setIsOpen(false);
        }
        break;

      case "opportunity":
        if (permission?.showOpportunity) {
          navigate(
            `/opportunity/overview/${item?.id}?${createSearchParams({
              tab: "overview",
            })}`
          );
          setIsOpen(false);
        }
        break;

      case "project":
        if (permission?.showProject) {
          handleProjectInfo(item);
          setIsOpen(false);
        }
        break;

      case "ticket":
        if (permission?.showTicket) {
          handleTicketInfo(item);
          setIsOpen(false);
        }
        break;

      case "task":
        if (permission?.showTask) {
          handleTaskInfo(item);
          setIsOpen(false);
        }
        break;

      default:
        break;
    }
  };

  function keyPress(event) {
    if (event.key === "Escape") {
      setIsOpen((prev) => !prev);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {};

  const excludedKeys = ["id", "model", "matched_fields"];

  const isExcludedKey = (key) => {
    return excludedKeys.includes(key) || key.endsWith("_id");
  };

  const renderHighlightedText = (htmlString) => {
    if (typeof htmlString === "string") {
      const modifiedString = htmlString
        .replace(/<em>/g, `<strong class="highlight">`)
        .replace(/<\/em>/g, "</strong>");
      return <span dangerouslySetInnerHTML={{ __html: modifiedString }} />;
    }
    return "";
  };

  const convertObjectToString = (obj) => {
    if (!obj || typeof obj !== "object") return "";
    return Object.values(obj)
      .filter((value) => value != null)
      .map((value) =>
        typeof value === "object" ? JSON.stringify(value) : value
      )
      .join(", ");
  };

  const convertArrayToString = (array, arrayKey) => {
    if (!Array.isArray(array) || array.length === 0) return "";

    const arrayString = array
      .map((item) => {
        if (!item || typeof item !== "object") return "";
        const values = Object.entries(item)
          .filter(([key]) => !isExcludedKey(key))
          .map(([_, value]) =>
            typeof value === "object" ? convertObjectToString(value) : value
          )
          .filter(Boolean)
          .join(", ");
        return values;
      })
      .filter(Boolean)
      .join(" | ");

    return array.length > 0
      ? `<strong>${
          arrayKey.charAt(0).toUpperCase() + arrayKey.slice(1)
        } - </strong>${arrayString}`
      : "";
  };

  const makeSubText = (array, arrayKey) => {
    const newText = renderHighlightedText(
      convertArrayToString(array, arrayKey)
    );
    return newText;
  };

  const createFormattedFields = (formattedData) => {
    if (!formattedData || typeof formattedData !== "object") return [];

    return Object.entries(formattedData)
      .filter(([key]) => !isExcludedKey(key))
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return makeSubText(value, key);
        } else if (value) {
          return renderHighlightedText(value);
        }
        return "";
      })
      .filter(Boolean);
  };

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const iconMap = {
    lead: (
      <SiGoogleadmob
        size={20}
        className="bg-light rounded-2 p-1"
        color="#FF8A08"
      />
    ),
    todo: (
      <FontAwesomeIcon
        icon="list-check"
        size={"lg"}
        className="bg-light rounded-0 p-1"
        color="#2A629A"
      />
    ),
    customer: (
      <FaUserTie size={20} className="bg-light rounded-2 p-1" color="#FD9B63" />
    ),
    employee: (
      <FaUsers size={23} className="bg-light rounded-2 p-1" color="#9C8AFF" />
    ),
    opportunity: (
      <TbBulbFilled
        size={23}
        className="bg-light rounded-2 p-1"
        color="#433D8B"
      />
    ),
    project: (
      <AiOutlineCodeSandbox
        size={23}
        className="bg-light rounded-2 p-1"
        color="#E4003A"
      />
    ),
    ticket: (
      <BiSupport size={23} className="bg-light rounded-2 p-1" color="#FFB22C" />
    ),
    task: (
      <BiTask size={23} color="#FFA38F" className="bg-light rounded-2 p-1" />
    ),
    contact: (
      <IoMdContact
        size={23}
        color="#FFA38F"
        className="bg-light rounded-2 p-1"
      />
    ),
    meeting: (
      <IoMdPeople
        size={22}
        color="#FFB22C"
        className="bg-light rounded-2 p-1"
      />
    ),
    // Add other types similarly
  };

  const getIconsWithType = (type) =>
    iconMap[type] || (
      <AiOutlineFileSearch
        size={23}
        className="bg-light rounded-2 p-1"
        color="#FF8A08"
      />
    );

  const getTotalCount = (data = {}) => {
    return Object.values(data)
      .map((obj) => obj.hits.length)
      .reduce((acc, length) => acc + length, 0);
  };

  const handleClose = () => {
    setSearchInputValue("");
    setData([]);
    setIsOpen(false);
  };

  return (
    <>
      <Form className="position-relative" onSubmit={(e) => e.preventDefault()}>
        <Form.Control
          type="search"
          placeholder="Search..."
          className="rounded-pill search-input ps-4"
          value={searchInputValue}
          onChange={({ target }) => setSearchInputValue(target.value)}
          onKeyPress={handleKeyPress}
          style={{ width: "20rem" }}
          aria-label="Search"
        />
        <FontAwesomeIcon
          icon="search"
          className="position-absolute text-400 search-box-icon"
          style={{
            top: "50%",
            left: "0.5rem",
            transform: "translateY(-48%)",
          }}
        />
      </Form>

      <Modal
        size="lg"
        show={isOpen}
        className="modal-with-overlay"
        animation
        centered
      >
        <Modal.Header>
          <Form.Control
            ref={searchInputRef}
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input p-1 px-2"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onKeyPress={handleKeyPress}
          />
          <CustomSearchButton
            className={"ms-2"}
            variant="falcon-default"
            title="Close"
            onClick={handleSearch}
          />
          <CustomBackButton
            className={"ms-2"}
            variant="falcon-default"
            title="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="p-2">
          {searchInputValue?.length < 2 && data?.length === 0 ? (
            <span className="p-2">
              Please enter at least 3 characters and click enter for searching
            </span>
          ) : (
            <>
              {dataProcessing ? (
                <ListLoading
                  style={{
                    height: "170px",
                    maxHeight: "250px",
                    overflow: "hidden",
                  }}
                />
              ) : Object.keys(data)?.length > 0 ? (
                <SimpleBar style={{ maxHeight: "70dvh" }} className="p-2">
                  <div className="fs-1 text-dark mb-3 border-bottom pb-3">
                    Top Results ({getTotalCount(data)})
                  </div>
                  {Object.entries(data)?.map(([type, typeData]) => (
                    <div
                      key={type}
                      className="mb-3 border-bottom"
                      style={{ width: "100%", color: "black" }}
                    >
                      {/* Display type as heading */}
                      <div className="fw-bold text-uppercase pb-1 mb-1">
                        {type} ({typeData?.hits?.length})
                      </div>
                      {/* Map over each hit in the type */}
                      {typeData?.hits?.map((hit, index) => {
                        const id = hit?._formatted?.id;
                        let formattedFields = [];
                        if (hit._formatted) {
                          formattedFields = createFormattedFields(
                            hit._formatted
                          )
                            .filter(Boolean)
                            .reduce((acc, curr) => [...acc, curr, " | "], [])
                            .slice(0, -1);
                          formattedFieldsRef.current = formattedFields;
                        }

                        return (
                          <div
                            key={`${type}-${index}`}
                            className="py-2 mb-2 d-flex gap-1 cursor-pointer link-like-text"
                            style={{ backgroundColor: "white" }}
                            onClick={() => {
                              handleRowClick(type, { id: id });
                            }}
                          >
                            <div>{getIconsWithType(type)}</div>
                            <div>
                              {formattedFieldsRef.current?.length > 0 ? (
                                <span>{formattedFieldsRef.current}</span>
                              ) : (
                                <span>No Data</span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </SimpleBar>
              ) : (
                <span className="p-2">No Data Found</span>
              )}{" "}
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <span
            onClick={handleNavigate}
            className="link-like-text d-flex align-items-baseline gap-1"
          >
            Advance Search
            <RedirectButton onClick={handleNavigate} />
          </span>
        </Modal.Footer>
      </Modal>
      {leadInfo?.show && (
        <LeadOffcanvas
          show={leadInfo?.show}
          id={leadInfo?.id}
          onHide={() => setLeadInfo({ show: false, id: "" })}
        />
      )}
      {todoInfo?.show && (
        <TodoInfo
          show={todoInfo?.show}
          id={todoInfo?.id}
          onHide={() => setTodoInfo({ show: false, id: "" })}
        />
      )}
      {projectInfo?.show && (
        <ProjectOffcanvas
          show={projectInfo?.show}
          id={projectInfo?.id}
          onHide={() => setProjectInfo({ show: false, id: "" })}
        />
      )}
      {ticketInfo?.show && (
        <TicketInfo
          show={ticketInfo?.show}
          id={ticketInfo?.id}
          onHide={() => setTicketInfo({ show: false, id: "" })}
          getDataRefresh={getData}
        />
      )}
      {taskInfo?.show && (
        <TaskInfo
          taskInfoId={taskInfo?.id}
          showTaskInfo={taskInfo?.show}
          handleCloseTaskInfo={() => setTaskInfo({ show: false, id: "" })}
          getData={getData}
        />
      )}
    </>
  );
};

export default SearchBox;
