// const exportReducer = (state, action) => {
//   console.log("action", action);
//   switch (action.type) {
//     case "ADD":
//       return [
//         {
//           ...action.payload,
//           id: state.length + 1,
//           status: false,
//           request_status: false,
//         },
//         ...state,
//       ];

//     case "DELETE":
//       return state.map((item) =>
//         item.id === action.payload.id ? { ...item, deleted: true } : item
//       );

//     case "UPDATE":
//       return state.map((item) =>
//         item.id === action.payload.id ? action.payload : item
//       );

//     case "UPDATE-STATUS-BY-FILE-URL":
//       if (action.payload?.status === "INITIATED") {
//         return [
//           {
//             ...action.payload,
//             id: state.length + 1,
//             request_status: false,
//           },
//           ...state,
//         ];
//       } else {
//         return state.map((item) =>
//           item.file_url === action.payload.file_url ||
//           item.file_name === action.payload.file_name
//             ? { ...item, ...action.payload }
//             : item
//         );
//       }

//     default:
//       return state;
//   }
//   };
//   export default exportReducer;

const exportReducer = (state, action) => {
  switch (action.type) {
    case "ADD":
      // Remove any existing item with the same file_name or file_url
      const filteredState = state.filter(
        (item) =>
          item.file_name !== action.payload.file_name &&
          item.file_url !== action.payload.file_url
      );

      // Add the new item with updated ID
      return [
        {
          ...action.payload,
          id: filteredState.length + 1, // Setting a new id based on filtered state
          status: false,
          request_status: false,
        },
        ...filteredState,
      ];

    case "DELETE":
      return state.map((item) =>
        item.id === action.payload.id ? { ...item, deleted: true } : item
      );

    case "DELETE_ALL":
      return [];

    case "UPDATE":
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case "UPDATE-STATUS-BY-FILE-URL":
      if (action.payload?.status === "INITIATED") {
        // Check if an item with the same file_name already exists
        const existingItem = state.find(
          (item) =>
            item.file_name === action.payload.file_name ||
            item.file_url === action.payload.file_url
        );

        // If an item exists, update it; otherwise, add a new entry
        if (existingItem) {
          return state.map((item) =>
            item.file_name === action.payload.file_name ||
            item.file_url === action.payload.file_url
              ? { ...item, ...action.payload }
              : item
          );
        } else {
          // Add the new item if not found
          return [
            {
              ...action.payload,
              id: state.length + 1,
              request_status: false,
            },
            ...state,
          ];
        }
      } else if (action.payload?.status === "COMPLETED") {
        // Remove the earlier entry with the same file_name or file_url
        const updatedState = state.filter(
          (item) =>
            item.file_name !== action.payload.file_name &&
            item.file_url !== action.payload.file_url
        );

        // Add the new COMPLETED entry
        return [
          {
            ...action.payload,
            id: updatedState.length + 1,
            request_status: true, // Assuming you want to mark request as completed
          },
          ...updatedState,
        ];
      }
      break;

    default:
      return state;
  }
};

export default exportReducer;
