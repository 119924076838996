import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Image, ListGroupItem } from "react-bootstrap";
import DefaultAvathar from "assets/img/Avathar/ProfileImage.jpg";
import { DeleteButton, EditButton } from "../Buttons/AppButtons";
import { DeleteContext } from "context/Context";
import {
  FaCloudDownloadAlt,
  FaFileArchive,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
} from "react-icons/fa";
import { CgAttachment } from "react-icons/cg";
import { AiFillFileUnknown } from "react-icons/ai";
import { Link, createSearchParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "../Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import ListLoading from "../ListLoading";
import NoteForm from "./NoteForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";

function LogNote({ itemId, type }) {
  const [noteData, setNoteData] = useState([]);
  const [formData, setFormData] = useState({
    note: "",
    files: [],
  });
  const [formError, setFormError] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const refFileControl = useRef();
  const [onSave, setOnSave] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axios
      .get("crm/crm-notes", {
        params: {
          subject_type: type === "OPPORTUNITY" ? "OPPORTUNITY" : "LEAD",
          subject_id: itemId,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setNoteData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let newOldData = [];

    if (!action) {
      if (e.target.name === "files") {
        let fileItem = e.target.files[0];
        if (fileItem) {
          newOldData.push(fileItem);
          let fileSizeInMB = (fileItem?.size / Math.pow(1024, 2)).toFixed(2);
          let fileExtension = fileItem?.name.split(".").pop();
          let allowedExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "csv",
            "odt",
            "xls",
            "xlsx",
            "zip",
            "rar",
            "tar",
            "mp3",
            "mp4",
            "avi",
            "wmv",
            "mov",
            "mkv",
          ].includes(fileExtension);
          if (!allowedExtensions) {
            showToast("The file format is invalid", "error");
            return;
          } else if (fileSizeInMB > 2) {
            showToast(
              "The file size is excessive! The file will be skipped",
              "error"
            );
            return;
          } else {
            newFormData = {
              ...formData,
              [e.target.name]: [...formData?.files, ...newOldData],
            };

            setAttachments((oldArray) => [...oldArray, ...newOldData]);
          }
        }
      } else {
        value = e.target.value;
        newFormData = {
          ...formData,
          [e.target.name]: value,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setOnSave(true);
    setFormError({});
    formData.subject_id = itemId;
    formData.subject_type = type === "OPPORTUNITY" ? "OPPORTUNITY" : "LEAD";
    const newFormData = setNewFormData(formData);
    axios({
      method: "post",
      url: `crm/crm-notes`,
      data: newFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          setNoteData((prev) => [response.data.data, ...prev]);
          setFormData({ ...formData, note: "", files: [] });
          setAttachments([]);
          setOnSave(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error.response?.data && error.response?.data.message) {
          const validation_error =
            error.response?.data &&
            error.response?.data.data &&
            error.response?.data.data.errors
              ? error.response?.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error.response?.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "crm/crm-notes/" + id,
        title: `Delete Note`,
        message: `Are you sure you want to delete this Note?`,
        onSuccess: () => {
          const filterData = noteData.filter((item) => item.id !== id);
          setNoteData(filterData);
        },
      },
    });
  };

  const handleDetachAttachment = (name) => {
    setAttachments(
      attachments.filter((attachment) => attachment.name !== name)
    );
    setFormData({
      ...formData,
      files: formData.files.filter((attachment) => attachment.name !== name),
    });
  };

  const getIcon = (type) => {
    const icon = ["far"];
    if (type.includes("image")) {
      icon.push("file-image");
    }
    if (type.includes("video")) {
      icon.push("file-video");
    }
    if (type.includes("audio")) {
      icon.push("file-audio");
    }
    if (type.includes("zip")) {
      icon.push("file-archive");
    }
    if (type.includes("pdf")) {
      icon.push("file-pdf");
    }
    if (
      type.includes("html") ||
      type.includes("css") ||
      type.includes("json") ||
      type.includes("javascript")
    ) {
      icon.push("file-code");
    }
    if (icon.length === 1) {
      icon.push("file");
    }
    return icon;
  };

  return (
    <Card className="card-main rounded-0 shadow-none h-100">
      <Card.Header
        className={`rounded-0 pb-2 pt-2`}
        style={{
          backgroundColor: "#5f6f83",
          color: "#fff",
        }}
      >
        <span className="mb-0 fs--1">LOG NOTE</span>
        <NoteForm type={type} noteData={noteData} setNoteData={setNoteData} />
      </Card.Header>
      <Card.Body
        className="p-2 pt-3"
        style={{ height: "70vh", overflow: "auto" }}
      >
        <div className="p-3 pt-0 ps-2 pe-2 border-bottom">
          <div className="border p-2">
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={3}
                name="note"
                placeholder="Leave a Note..."
                className="ps-1 p-0 border-0 shadow-none"
                onChange={handleFieldChange}
                value={formData?.note}
                isInvalid={!!formError.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError.description}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex align-items-center">
              <div className={attachments.length > 0 ? "border-end" : ""}>
                <Form.Control
                  ref={refFileControl}
                  type="file"
                  multiple
                  className="d-none"
                  name="files"
                  onChange={handleFieldChange}
                />
                <Button
                  className=" p-1 pb-1 small d-flex  align-items-center  border-0 rounded-4 pe-2 me-2 "
                  style={{ backgroundColor: "#EDF2F9", width: "max-content" }}
                  onClick={() => refFileControl.current.click()}
                >
                  <CgAttachment
                    className="common-comments-header-username text-500"
                    size={17}
                  />
                  <div className="common-comments-header-username ms-1 small text-500">
                    Upload File
                  </div>
                </Button>
              </div>
              {attachments.length > 0 && (
                <div className="overflow-auto note-files-scroll-bar ps-2">
                  <Flex direction="row" inline alignItems="start" className="">
                    {attachments.map((attachment, key) => (
                      <Flex
                        alignItems="center"
                        className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs--1 me-2"
                        key={key}
                      >
                        <FontAwesomeIcon
                          icon={getIcon(attachment.type)}
                          className="fs-1"
                        />
                        <span
                          className="mx-2 flex-1"
                          style={{ width: "max-content", fontSize: "11px" }}
                        >
                          {attachment.name} (
                          {(attachment.size / 1024).toFixed(2)}
                          kb)
                        </span>
                        <span
                          className="text-300 p-1 ml-auto cursor-pointer"
                          id={`attachmentTooltip${key}`}
                          onClick={() =>
                            handleDetachAttachment(attachment.name)
                          }
                        >
                          <FontAwesomeIcon icon="times" />
                        </span>
                      </Flex>
                    ))}
                  </Flex>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button
              className="mb-0 fw-bold text-white p-2 ps-3 pe-3 border-0 rounded-0"
              variant="primary"
              style={{
                fontSize: "12px",
              }}
              onClick={(e) =>
                formData.note !== ""
                  ? handleSubmit(e)
                  : setFormError({ description: "Note Field is Required" })
              }
              disabled={onSave}
            >
              Add Note
            </Button>
          </div>
        </div>
        <div
          className="m-2 mt-3"
          style={{ height: "48vh", overflow: "none", overflowY: "scroll" }}
        >
          {noteData?.length > 0 && !loading ? (
            noteData?.map((el) => (
              <div
                key={el.id}
                style={{ backgroundColor: "whitesmoke" }}
                className="p-3 pb-2 mb-3"
              >
                <div className="d-flex align-items-start justify-content-between me-1">
                  <div className="d-flex align-items-center">
                    <Image
                      src={el?.profile_pic ? el?.profile_pic : DefaultAvathar}
                      height={35}
                      width={35}
                      alt="?"
                      roundedCircle
                    />
                    <div
                      className=" mb-0 fw-bold ms-2"
                      style={{ fontSize: "13px" }}
                    >
                      {el.created_by}
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-end"
                    style={{ lineHeight: 1.6 }}
                  >
                    <span
                      style={{ fontSize: "11px" }}
                      className="text-dark fw-bold"
                    >
                      {el?.created_date}
                    </span>
                    <span style={{ fontSize: "11px" }} className="text-dark">
                      {el?.created_time}
                    </span>
                  </div>
                </div>
                <div className="mt-2 p-1 pe-0 ps-2 d-flex align-items-end">
                  <div className="w-100">
                    <p className="mb-1 ms-2 mt-1">{el.description}</p>
                    {el?.media.length > 0 &&
                      el?.media.map((file) => (
                        <ListGroupItem
                          key={file.id}
                          className={`rounded-0 pe-2 ps-2 mb-0 mt-2 border`}
                        >
                          <div className="d-flex align-items-center">
                            <div className="me-2">
                              <Button
                                size="sm"
                                className="p-0 custom-transperent-common-button"
                                variant="transparent"
                              >
                                {file?.file_name.split(".").pop() === "png" ||
                                file?.file_name.split(".").pop() === "jpg" ? (
                                  <FaFileImage size={28} />
                                ) : file?.file_name.split(".").pop() ===
                                  "pdf" ? (
                                  <FaFilePdf color="#fb3939b8" size={28} />
                                ) : file?.file_name.split(".").pop() ===
                                    "xls" ||
                                  file?.file_name.split(".").pop() ===
                                    "xlsx" ? (
                                  <FaFileExcel color="#007f3eb5" size={28} />
                                ) : file?.file_name.split(".").pop() ===
                                  "csv" ? (
                                  <FaFileCsv color="#41a754cc" size={28} />
                                ) : file?.file_name.split(".").pop() ===
                                  "doc" ? (
                                  <FaFileWord color="#285293" size={28} />
                                ) : file?.file_name.split(".").pop() ===
                                  "zip" ? (
                                  <FaFileArchive color="#285293" size={28} />
                                ) : (
                                  <AiFillFileUnknown size={28} />
                                )}
                              </Button>
                            </div>
                            <div
                              className="d-flex flex-column"
                              style={{ lineHeight: 1.4 }}
                            >
                              <Link
                                className="fw-bold text-dark"
                                target="_blank"
                                to={file?.file_url}
                                style={{ fontSize: "12px" }}
                              >
                                {file?.file_name.length > 65
                                  ? file?.file_name.substring(0, 65) +
                                    "." +
                                    file?.file_name.split(".").pop()
                                  : file?.file_name}
                              </Link>
                              <div>
                                <span className="custom-text-muted-crm">
                                  {file?.human_readable_size}
                                </span>
                              </div>
                            </div>
                            <div className="ms-auto">
                              <Button
                                as={Link}
                                target="_blank"
                                to={file?.file_url}
                                variant="transparent"
                                size="sm"
                                className="custom-transperent-common-button p-0 me-1"
                              >
                                <FaCloudDownloadAlt color="#718dd6" size={18} />
                              </Button>
                            </div>
                          </div>
                        </ListGroupItem>
                      ))}
                  </div>
                  <div className="d-flex align-items-center ms-3">
                    <EditButton
                      route={`?${createSearchParams({
                        tab: "note",
                        edit_note: true,
                        edit_note_id: el?.id,
                      })}`}
                    />
                    <DeleteButton onClick={() => handleDelete(el?.id)} />
                  </div>
                </div>
              </div>
            ))
          ) : loading ? (
            <ListLoading style={{ height: "50vh", overflow: "hidden" }} />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ height: "16rem" }}
            >
              <span className="">No Log Note Added Yet &#128521;</span>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default LogNote;
