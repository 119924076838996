import axios from "axios";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import { loginFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { showToast } from "module/common/Toast/toast";
import React, { useContext, useState } from "react";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { FaArrowRight, FaKey, FaLock, FaUser } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";

const LoginForm = () => {
  const { dispatch } = useContext(AuthWizardContext);
  const [authenticatingUser, setAuthenticatingUser] = useState(false);
  const [formData, setFormData] = useState(loginFormKeys);
  const [errors, setErrors] = useState({});
  const [queryParams] = useSearchParams();
  const loginAs = queryParams.get("as");

  const handleFieldChange = (e) =>
    setFormData(generateFormDataHelper(e, null, formData));

  const getUserInfo = async () => {
    let userData = JSON.parse(localStorage.getItem("AXIS_PRO_CRM_USER"));
    let data = userData;
    if (userData) {
      try {
        const timerData = await apiCall({
          url: "crm/running-tasks-info",
        });

        //update timer data
        data.running_tasks_count = timerData?.count;
        data.running_task_id =
          timerData?.tasks?.length > 0 ? timerData?.tasks[0].id : null;

        dispatch({
          type: "UPDATE",
          payload: data,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPendingTasks = async () => {
    try {
      const data = await apiCall({
        url: "crm/notification-for-pending-tasks",
      });
      if (data?.total > 0) {
        localStorage.setItem("pending_task_data", JSON.stringify(data));
        localStorage.setItem("lastApiCallTime", Date.now());
        localStorage.setItem("show_pending_task_modal", JSON.stringify(true));
      }
    } catch (error) {
      console.error("Error fetching pending tasks:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (authenticatingUser) return;
    setErrors({});

    // Validate form inputs
    if (!formData.username || !formData.password) {
      return showToast(
        `Invalid request, ${
          !formData.username && !formData.password
            ? "username and password"
            : !formData.username
            ? "username"
            : "password"
        } is required.`,
        "error"
      );
    }

    setAuthenticatingUser(true);

    axios
      .post(`${loginAs === "user" ? "auth/login" : "customer/login"}`, formData)
      .then((response) => {
        if (response?.data?.success !== true) {
          setFormData(loginFormKeys);
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }

        const userData = {
          ...response.data.data,
          username: formData.username,
          logged_as: loginAs,
          lockScreen: false,
          temporary_sales_email: response.data.data?.primary_sales_email
            ? response.data.data?.primary_sales_email
            : "",
        };

        // save user data with token to localStorage
        localStorage.setItem("AXIS_PRO_CRM_USER", JSON.stringify(userData));

        // update auth context
        dispatch({ type: "UPDATE", payload: { ...userData } });

        showToast(`Successfully logged in as ${userData.name}`, "success");
        if (loginAs === "user") {
          getUserInfo();
          getPendingTasks();
        }
        setAuthenticatingUser(false);
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          return showToast(
            `The server was unable to establish a secure connection.`,
            "error"
          );
        } else {
          if (!error.response.data || !error.response.data.message) {
            return showToast(
              `Something went wrong, please refresh the page and try again.`,
              "error"
            );
          }

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;

          if (validation_error) {
            setErrors({ ...validation_error });
          } else {
            showToast(
              error.response.data.message ||
                `Something went wrong, please refresh the page and try again.`,
              "error"
            );
          }
        }
        setAuthenticatingUser(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {Object.keys(errors).length > 0 ? (
        <Form.Group className="mb-3">
          <Alert variant="danger">{errors.password || errors.username}</Alert>
        </Form.Group>
      ) : null}
      <Form.Group className="mb-3" controlId="username">
        <Form.Label>Username</Form.Label>
        <InputGroup
          className={` rounded shadow py-1 ${
            formData.username.length > 0 && !errors.username
              ? "is-valid"
              : errors.username
              ? "is-invalid"
              : ""
          }`}
        >
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <FaUser size={10} />
          </InputGroup.Text>
          <Form.Control
            className="border-0"
            placeholder="Enter username"
            name="username"
            onChange={handleFieldChange}
            value={formData.username}
            isValid={formData.username.length > 0 && !errors.username}
            isInvalid={!!errors.username || !!errors.password}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <InputGroup
          className={` rounded shadow py-1 ${
            formData.password.length > 0 && !errors.password
              ? "is-valid"
              : errors.password
              ? "is-invalid"
              : ""
          }`}
        >
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <FaLock size={10} />
          </InputGroup.Text>
          <Form.Control
            type="password"
            placeholder="Enter password"
            name="password"
            onChange={handleFieldChange}
            value={formData.password}
            isValid={formData.password.length > 0 && !errors.password}
            isInvalid={!!errors.password}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="d-flex flex-row">
        <Button
          type="submit"
          className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
          disabled={authenticatingUser}
          style={{ backgroundColor: "#6AABAC", borderColor: "#6AABAC" }}
        >
          {authenticatingUser ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
                style={{ width: "12px", height: "12px" }}
              />
              <span className="fs--1">Authenticating...</span>
            </>
          ) : (
            <>
              <span>Log in</span>
              <FaArrowRight className="ms-2" size={15} />
            </>
          )}
        </Button>
      </Form.Group>

      <Form.Group className="mb-3 mt-3 d-flex justify-content-center align-items-center">
        <Button
          className={`d-flex flex-row align-items-center rounded py-1 ${"border-0"} `}
          size="sm"
          variant={"outline-secondary"}
          style={{ boxShadow: "none" }}
          as={Link}
          to={`/authentication/account-recovery?as=${loginAs}`}
        >
          <FaKey size={13} />
          <span className="ms-1 small">Recover your Account</span>
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
