import React, { useContext, useEffect, useState } from "react";
import { Modal, Badge, Table } from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";
import { RedirectButton } from "module/common/Buttons/AppButtons";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import { AuthWizardContext } from "context/Context";
import { AiOutlineWarning } from "react-icons/ai";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";

const TaskNotificationPopup = () => {
  const { user, dispatch } = useContext(AuthWizardContext);
  const [data, setData] = useState({
    data: [],
  });

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.showTaskNotificationPopup) {
      setShow(true);
      if (user?.taskNotificationData) {
        setData((prev) => {
          const newData = user?.taskNotificationData?.data;
          if (prev.data.length > 0) {
            return {
              ...prev,
              data: [...prev.data, ...newData],
            };
          } else {
            return {
              ...prev,
              data: newData,
            };
          }
        });
      }
    } else {
      setShow(false);
      setData({
        data: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.showTaskNotificationPopup, user?.taskNotificationData]);

  const taskNumbers = data?.data?.map((task) => task?.task_number).join(", ");

  const handleNavigate = () => {
    navigate(`/tasks/list?${createSearchParams({ task_no: taskNumbers })}`);
    handleHide();
  };

  const handleRowClick = (task_no) => {
    navigate(`/tasks/list?${createSearchParams({ task_no: task_no })}`);
    handleHide();
  };

  const checkNotifiedTasksInLocalStorage = () => {
    const storageKey = "notifiedTasks";
    const twelveHours = 12 * 60 * 60 * 1000;
    const currentTimeStamp = Date.now();

    let notifiedTasksFromLocalStorage =
      JSON.parse(localStorage.getItem(storageKey)) || [];

    const updatedNotifiedTasks = notifiedTasksFromLocalStorage.filter(
      (notification) => currentTimeStamp - notification.id <= twelveHours
    );

    if (updatedNotifiedTasks.length !== notifiedTasksFromLocalStorage.length) {
      localStorage.setItem(storageKey, JSON.stringify(updatedNotifiedTasks));
    }
  };

  let timeoutId = null;

  const setupTimeoutCheck = (delay) => {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      checkNotifiedTasksInLocalStorage();
      setupTimeoutCheck(delay);
    }, delay || 1000);
  };

  useEffect(() => {
    setupTimeoutCheck(1000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DynamicTable = (data) => {
    return (
      <Table striped bordered hover responsive className="m-0">
        <thead>
          <tr>
            <th
              className="text-capitalize fw-bold bg-dark-subtle"
              style={{ width: "max-content", color: "white" }}
            >
              Task No
            </th>
            <th
              className="text-capitalize fw-bold bg-dark-subtle"
              style={{ width: "max-content", color: "white" }}
            >
              Task Name
            </th>
            <th
              className="text-capitalize fw-bold bg-dark-subtle text-center"
              style={{ width: "max-content", color: "white" }}
            >
              Priority
            </th>
            <th
              className="text-capitalize fw-bold bg-dark-subtle text-center"
              style={{ width: "max-content", color: "white" }}
            >
              Status
            </th>
            <th
              className="text-capitalize fw-bold bg-dark-subtle text-center"
              style={{ width: "max-content", color: "white" }}
            >
              View
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((task) => (
            <tr
              onClick={() => handleRowClick(task?.task_number)}
              className="cursor-pointer"
              key={task?.task_number}
            >
              <td>#{task?.task_number}</td>
              <td>{task?.title}</td>
              <td className="text-center">
                <Badge
                  bg="none"
                  style={{
                    backgroundColor: getPriorityWithProps(task?.priority),
                  }}
                >
                  {task?.priority}
                </Badge>
              </td>
              <td className="text-center">
                <Badge
                  bg="none"
                  style={{
                    backgroundColor: getTaskStatusWithProps(task?.status),
                    minWidth: "120px",
                    fontSize: "11px",
                  }}
                  className="text-capitalize"
                >
                  {task?.status.replace("_", " ")}
                </Badge>
              </td>
              <td>
                <RedirectButton
                  onClick={() => handleRowClick(task?.task_number)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const handleHide = () => {
    const updatedUser = {
      ...user,
      taskNotificationData: { data: [] },
      showTaskNotificationPopup: false,
    };

    dispatch({
      type: "UPDATE",
      payload: updatedUser,
    });
    setShow(false);
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        // backdrop="static"
        keyboard={false}
        centered
        onHide={handleHide}
        className={show ? "modal-shake" : ""}
      >
        <Modal.Header
          closeButton
          className="text-dark d-flex justify-content-between"
        >
          <div
            style={{ fontSize: "16px", width: "100%" }}
            className="d-flex align-items-center"
          >
            <AiOutlineWarning className="me-1" size={20} color="#ff5500" />
            Task Reminder
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <p className="text-dark mb-1">
              Hey {user?.name}, you have these assigned tasks pending
            </p>
            <DynamicTable data={data?.data} />
          </>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <span
            className="link-text d-flex align-items-baseline gap-1"
            onClick={handleNavigate}
          >
            View all
            <RedirectButton onClick={handleNavigate} />
          </span>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaskNotificationPopup;
