import { Button, Form, ListGroupItem, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { MdOutlineNoteAlt } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import { apiCall } from "helpers/apiCalls";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import { SaveButton } from "../Buttons/CommonButton";
import BackButton from "components/common/BackButton";
import {
  FaCloudDownloadAlt,
  FaFileArchive,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
} from "react-icons/fa";
import { AiFillFileUnknown } from "react-icons/ai";
import { Link } from "react-router-dom";
import { CgAttachment } from "react-icons/cg";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoteForm = ({ type, noteData, setNoteData }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    note: "",
    files: [],
  });
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const show = queryParams.get("edit_note") ? true : false;
  const edit_id = queryParams.get("edit_note_id");
  const [onSave, setOnSave] = useState(false);
  const { itemId, overviewId } = useParams();
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const refFileControl = useRef();

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let newOldData = [];

    if (!action) {
      if (e.target.name === "files") {
        let fileItem = e.target.files[0];
        if (fileItem) {
          newOldData.push(fileItem);
          let fileSizeInMB = (fileItem?.size / Math.pow(1024, 2)).toFixed(2);
          let fileExtension = fileItem?.name.split(".").pop();
          let allowedExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "csv",
            "odt",
            "xls",
            "xlsx",
            "zip",
            "rar",
            "tar",
            "mp3",
            "mp4",
            "avi",
            "wmv",
            "mov",
            "mkv",
          ].includes(fileExtension);
          if (!allowedExtensions) {
            showToast("The file format is invalid", "error");
            return;
          } else if (fileSizeInMB > 2) {
            showToast(
              "The file size is excessive! The file will be skipped",
              "error"
            );
            return;
          } else {
            newFormData = {
              ...formData,
              [e.target.name]: [...formData?.files, ...newOldData],
            };
            setAttachments((oldArray) => [...oldArray, ...newOldData]);
          }
        }
      } else {
        value = e.target.value;
        newFormData = {
          ...formData,
          [e.target.name]: value,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);
    formData.subject_id = overviewId ? overviewId : itemId;
    formData.subject_type =
      type === "LEAD" || overviewId ? "LEAD" : "OPPORTUNITY";
    formData._method = "PUT";
    const newFormData = setNewFormData(formData);
    axios({
      method: "POST",
      url: `crm/crm-notes/` + edit_id,
      data: newFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        setOnSave(false);
        if (response.data.success === true) {
          setFormData({
            note: "",
            files: [],
          });
          const updatedData = noteData.map((el) =>
            el.id === edit_id ? { ...el, ...response.data.data } : el
          );
          setNoteData(updatedData);
          showToast(response.data.message, "success");
          navigate(-1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        setOnSave(false);
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      });
  };

  const fetchData = async (edit_id) => {
    setIsLoading(true);
    const data = await apiCall({
      url: `crm/crm-notes/` + edit_id,
    });
    setFormData({
      ...formData,
      note: data?.description,
      files: [],
    });

    setFiles(data?.media);
    setAttachments([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (edit_id && show) {
      fetchData(edit_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit_id]);

  const handleDetachAttachment = (name) => {
    setAttachments(
      attachments.filter((attachment) => attachment.name !== name)
    );
    setFormData({
      ...formData,
      files: formData.files.filter((attachment) => attachment.name !== name),
    });
  };

  const getIcon = (type) => {
    const icon = ["far"];
    if (type.includes("image")) {
      icon.push("file-image");
    }
    if (type.includes("video")) {
      icon.push("file-video");
    }
    if (type.includes("audio")) {
      icon.push("file-audio");
    }
    if (type.includes("zip")) {
      icon.push("file-archive");
    }
    if (type.includes("pdf")) {
      icon.push("file-pdf");
    }
    if (
      type.includes("html") ||
      type.includes("css") ||
      type.includes("json") ||
      type.includes("javascript")
    ) {
      icon.push("file-code");
    }
    if (icon.length === 1) {
      icon.push("file");
    }
    return icon;
  };

  return (
    <>
      <Modal show={show} size="lg" onHide={() => navigate(-1)}>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#EDF2F9" }}
          className="pt-1 pb-1"
        >
          <Modal.Title className="d-flex  align-items-center pt-2 pb-2">
            <MdOutlineNoteAlt size={24} className="me-2 text-dark" />
            <div className="fw-bold">EDIT NOTE</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading ? (
            <Form>
              <Form.Group>
                <Form.Label className="require-data">Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="note"
                  placeholder="Leave a Note..."
                  className="ps-2"
                  onChange={handleFieldChange}
                  value={formData.note}
                  isInvalid={!!formError.description}
                />
                <Form.Control.Feedback type="invalid">
                  {formError.description}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="d-flex">
                <div className={attachments.length > 0 ? "border-end" : ""}>
                  <Form.Control
                    ref={refFileControl}
                    type="file"
                    multiple
                    className="d-none"
                    name="files"
                    onChange={handleFieldChange}
                  />
                  <Button
                    className=" p-1 pb-1 small d-flex  align-items-center  border-0 rounded-4 pe-2 me-2 mt-2"
                    style={{ backgroundColor: "#EDF2F9", width: "max-content" }}
                    onClick={() => refFileControl.current.click()}
                  >
                    <CgAttachment
                      className="common-comments-header-username text-500"
                      size={17}
                    />
                    <div className="common-comments-header-username ms-1 small text-500">
                      Upload File
                    </div>
                  </Button>
                </div>
                {attachments.length > 0 && (
                  <div className="overflow-auto note-files-scroll-bar ps-2">
                    <Flex
                      direction="row"
                      inline
                      alignItems="start"
                      className=""
                    >
                      {attachments.map((attachment, key) => (
                        <Flex
                          alignItems="center"
                          className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs--1 me-2"
                          key={key}
                        >
                          <FontAwesomeIcon
                            icon={getIcon(attachment.type)}
                            className="fs-1"
                          />
                          <span
                            className="mx-2 flex-1"
                            style={{ width: "max-content", fontSize: "11px" }}
                          >
                            {attachment.name} (
                            {(attachment.size / 1024).toFixed(2)}
                            kb)
                          </span>
                          <span
                            className="text-300 p-1 ml-auto cursor-pointer"
                            id={`attachmentTooltip${key}`}
                            onClick={() =>
                              handleDetachAttachment(attachment.name)
                            }
                          >
                            <FontAwesomeIcon icon="times" />
                          </span>
                        </Flex>
                      ))}
                    </Flex>
                  </div>
                )}
              </div>
              {files?.length > 0 && (
                <div
                  style={{ height: files?.length > 3 ? "25vh" : "100%" }}
                  className="overflow-auto mt-2 mb-2 border-top border-bottom p-1 pe-1 ps-1"
                >
                  {files?.map((file) => (
                    <ListGroupItem
                      key={file.id}
                      className={`rounded-0 pe-2 ps-2 mb-2 mt-2 border-top`}
                    >
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          <Button
                            size="sm"
                            className="p-0 custom-transperent-common-button"
                            variant="transparent"
                          >
                            {file.file_name?.split(".").pop() === "png" ||
                            file.file_name?.split(".").pop() === "jpg" ? (
                              <FaFileImage size={28} />
                            ) : file?.file_name.split(".").pop() === "pdf" ? (
                              <FaFilePdf color="#fb3939b8" size={28} />
                            ) : file?.file_name.split(".").pop() === "xls" ||
                              file?.file_name.split(".").pop() === "xlsx" ? (
                              <FaFileExcel color="#007f3eb5" size={28} />
                            ) : file?.file_name.split(".").pop() === "csv" ? (
                              <FaFileCsv color="#41a754cc" size={28} />
                            ) : file?.file_name.split(".").pop() === "doc" ? (
                              <FaFileWord color="#285293" size={28} />
                            ) : file?.file_name.split(".").pop() === "zip" ? (
                              <FaFileArchive color="#285293" size={28} />
                            ) : (
                              <AiFillFileUnknown size={28} />
                            )}
                          </Button>
                        </div>
                        <div className="d-flex flex-column">
                          <Link
                            className="fw-bold text-dark"
                            target="_blank"
                            to={file?.file_url}
                            style={{ fontSize: "11px" }}
                          >
                            {file?.file_name?.length > 65
                              ? file?.file_name?.substring(0, 65) +
                                "." +
                                file?.file_name?.split(".").pop()
                              : file?.file_name}
                          </Link>
                          <div>
                            <span className="custom-text-muted-crm">
                              {file?.human_readable_size}
                            </span>
                          </div>
                        </div>

                        <div className="ms-auto">
                          <Button
                            as={Link}
                            target="_blank"
                            to={file?.file_url}
                            variant="transparent"
                            size="sm"
                            className="custom-transperent-common-button p-0 me-1"
                          >
                            <FaCloudDownloadAlt color="#718dd6" size={19} />
                          </Button>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </div>
              )}
              <Form.Group className="d-flex justify-content-end gap-1 mt-3">
                <SaveButton
                  handleSubmit={(e) =>
                    formData.note !== ""
                      ? handleSubmit(e)
                      : setFormError({ description: "Note field is required" })
                  }
                  onSave={onSave}
                  id={edit_id ?? ""}
                />
                {!onSave && (
                  <BackButton variant={"danger"} size={"sm"}>
                    Cancel
                  </BackButton>
                )}
              </Form.Group>
            </Form>
          ) : (
            <div className="d-flex" style={{ minHeight: "34vh" }}>
              <LoadingScreen message="Loading Note..." />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

NoteForm.prototype = {
  show: PropTypes.bool,
};

export default NoteForm;
