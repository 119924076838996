import useAxisproPermission from "hooks/useAxisproPermission";

const permissionActions = {
  store: "store",
  list: "list",
  update: "update",
  view: "view",
  create: "create",
  delete: "delete",
  export: "export",
  show: "show",
  report: "report",
};
const permissionType = {
  LEAD: "lead",
  LS: "lead-status",
  TASK: "task",
  AT: "assigned-tasks",
  CT: "created-tasks",
  TA: "task-assignee",
  CTO: "created-task-only",
  TS: "task-status",
  SPRINT: "sprint",
  SPRINT_STATUS: "sprint-status",
  PROJECT: "project",
  CUSTOMER: "customer",
  EMPLOYEE: "employee",
  OPPORTUNITY: "opportunity",
  TICKET: "ticket",
  ACCOUNT: "customer",
  TODO: "todo",
  CAMPAIGN: "campaign",
  ANNOUNCEMENT: "announcement",
  LABEL: "label",
  L_SOURCE: "lead-source",
  PRODUCT: "product",
  DEPARTMENT: "department",
  DESIGNATION: "designation",
  TICKET_TYPE: "ticket-type",
  USER: "user",
  ROLE: "role",
  LOG_HISTORY: "log-history",
  P_MEMBERS: "project-members",
  TEAM: "team",
  TICKET_STATUS: "ticket-status",
  SQ: "sq",
  DOCUMENT_TYPE: "document-type",
  OPPORTUNITY_CONTACT: "opportunity-contact",
  OPPORTUNITY_CALL: "opportunity-call",
  OPPORTUNITY_MEETING: "list-opportunity-meeting",
  CBS: "customer-balance-statements",
  CONTRACT: "ct",
};

export const GetAcodaxPermission = (type, action) => {
  const axisProPermission = useAxisproPermission();
  const formattedAction = permissionActions[action] || "unknown-action";
  const formattedType = permissionType[type] || "unknown-type";
  return axisProPermission(`${formattedAction}-${formattedType}`);
};
