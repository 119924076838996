import { useContext, useEffect } from "react";

import { AuthWizardContext, StoreContext } from "context/Context";
import { toast } from "react-toastify";
import axios from "axios";

function VersionNotification() {
  const { user } = useContext(AuthWizardContext);
  const { store, addIitemToStore } = useContext(StoreContext);

  const getLatestVersion = async () => {
    await axios
      .get("app-versions/latest/react")
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            addIitemToStore("latestVersion", res?.data?.data);
          }
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    getLatestVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.Echo.channel(`app-broadcast-${user?.branch_id}`).listen(
      "AcodaxAppBroadCast",
      (versionData) => {
        if (
          versionData?.type === "app-version.updated" &&
          versionData?.platform === "react"
        ) {
          if (versionData?.version !== store?.latestVersion?.version) {
            toast.info(
              `A new version of the app is available. Press Ctrl + F5 to get updated!`,
              {
                position: "top-center",
                autoClose: 10000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
              }
            );
          }
        }
      }
    );

    return () => {
      window.Echo.channel(`app-broadcast-${user?.branch_id}`).stopListening(
        "AcodaxAppBroadCast"
      );
    };
  }, [user?.branch_id, store?.latestVersion]);

  return null;
}

export default VersionNotification;
