import { React, useState, useContext } from "react";
import axios from "axios";
import { Button, Card, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { showToast } from "../Toast/toast";
import { DeleteContext } from "context/Context";
import { useSearchParams } from "react-router-dom";

function DeleteWarning({
  show,
  onHide,
  id,
  fetchData,
  data,
  setKey,
  heading,
  message,
  url,
  type,
  onDelete,
}) {
  const { config, dispatch } = useContext(DeleteContext);
  const [deleting, setDeleting] = useState(false);
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");

  const submitDelete = (e) => {
    if (config.url) {
      onSubmit(e);
    } else {
      handleDelete();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (typeof config.onDelete === "function") {
      config.onDelete();
    }
    const configData = config;
    dispatch({ type: "RESET" });
    axios({
      method: config.method ? "put" : "delete",
      url: configData.url && configData.url,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: "CONFIG",
            payload: config.method
              ? {
                  last_updated_item: configData.target
                    ? config.target
                    : undefined,
                }
              : {
                  last_deleted_item: configData.target
                    ? config.target
                    : undefined,
                },
          });
          if (
            typeof config.onSuccess === "function" &&
            response.data &&
            response.data.data
          ) {
            config.onSuccess(response.data.data);
          }
        } else {
          if (typeof config.onError === "function") {
            config.onError(response.data);
          }
        }
        showToast(
          response.data.success
            ? response.data.message
            : "Something went wrong, please refresh the page and try again.",
          response.data.success ? "success" : "error"
        );
      })
      .catch((error) => {
        if (typeof config.onError === "function") {
          config.onError(error);
        }

        const getErrorMessage = (key) =>
          error.response?.data?.data?.status === 422 &&
          error?.response?.data?.data?.errors &&
          error?.response?.data?.data?.errors[key]
            ? error.response?.data?.data?.errors[key][0]
            : error.response?.data?.message ||
              "Something went wrong, please refresh the page and try again.";

        const taskErrorMessage = getErrorMessage("task_id");
        const generalErrorMessage = getErrorMessage(0);

        if (taskErrorMessage) {
          showToast(taskErrorMessage, "error");
        } else if (generalErrorMessage) {
          showToast(generalErrorMessage, "error");
        }
      });
  };

  const handleDelete = () => {
    setDeleting(true);
    if (id && type === "ATTACHMENT") {
      axios
        .delete(`${url && url}/${id}`)
        .then((res) => {
          if (res.data.success === true) {
            if (data.length > 1) {
              if(fetchData && typeof fetchData === "function") fetchData();
              onHide();
              setKey("attachments");
              setDeleting(false);
            } else {
              if(fetchData && typeof fetchData === "function") fetchData();
              onHide();
              setKey("overview");
              setDeleting(false);
            }
          }
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            showToast(`Attachment not exist.`, "error");
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              "error"
            );
          }
          if (data.length > 1) {
            if(fetchData && typeof fetchData === "function") fetchData();
            onHide();
            setKey("attachments");
            setDeleting(false);
          } else {
            if(fetchData && typeof fetchData === "function") fetchData();
            onHide();
            setKey("overview");
            setDeleting(false);
          }
        });
    } else if ((id && type === "USER") || (id && type === "TASK")) {
      axios
        .delete(`${url && url}/${id}`)
        .then((response) => {
          if (response.data.success === true) {
            if (type === "TASK") {
              fetchData && fetchData(pageNumber, false);
              if (onDelete && typeof onDelete === "function") onDelete(id);
            }
            onHide();
          } else {
            showToast(response.data.message, "error");
            onHide();
          }
          setDeleting(false);
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            let message = error?.response?.data?.message;

            const validation_error = error?.response?.data?.data?.errors
              ? error.response.data.data.errors
              : null;
            if ("task_id" in validation_error) {
              message = validation_error.task_id[0];
            }

            if (id && type === "USER") {
              message = error.response?.data?.data?.errors[0][0];
            }
            showToast(message, "error");
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
          setDeleting(false);
          onHide("error");
        });
    }
  };

  const cancelModal = () => {
    if (typeof config.onCancel === "function") {
      config.onCancel();
    }
    if (config.url) {
      dispatch({ type: "RESET" });
    } else {
      onHide();
    }
  };

  return (
    <Modal show={config.url ? true : show ? show : false} size="md">
      <Card>
        <Card.Header className="text-center">
          <h5 className="fs--1 text-uppercase text-dark fw-bold">
            {config.title ? config.title : heading ? heading : "Delete"}
          </h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center p-0">
          <p className="m-0 fs--1">
            {config.message
              ? config.message
              : message
              ? message
              : "Are you sure you want to delete this item?"}
          </p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          <Button
            variant="danger"
            style={{ boxShadow: "none" }}
            onClick={submitDelete}
            size="sm"
            disabled={deleting}
          >
            {deleting ? "Deleting..." : "Delete"}
          </Button>
          <Button
            variant="success"
            style={{ boxShadow: "none" }}
            onClick={cancelModal}
            size="sm"
            disabled={deleting}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

DeleteWarning.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  id: PropTypes.any,
  fetchData: PropTypes.func,
  data: PropTypes.any,
  setKey: PropTypes.func,
  heading: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

export default DeleteWarning;
