import { React } from "react";
import PropTypes from "prop-types";
import useAutoCompleteApiCallHandler from "hooks/useAutoCompleteApiCallHandler";
import { FaCircle } from "react-icons/fa";
import getPriorityWithProps from "module/common/helpers/getPriorityWithProps";
import Select from "react-select";

const SelectPriority = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  isDisabled,
  onFocus,
  fetchUrl = "",
  clearable = true,
}) => {
  const { setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: fetchUrl,
      setParams: (searchKey) => ({ name: searchKey }),
      setOptions: (option) => setOptionData(option),
      onFocus: onFocus,
    });

  const setOptionData = (option) => ({
    label: option,
    value: option,
  });

  return (
    <Select
      defaultOptions
      isClearable={clearable}
      isLoading={loading}
      onFocus={setDefaultResult}
      options={defaultOptions}
      name={name ? name : "priority"}
      components={{ Option: CustomOption }}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      isDisabled={isDisabled}
      className={`custom-select ${
        error
          ? "form-control ps-0 py-0 is-invalid text-capitalize"
          : "text-capitalize"
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused
            ? state.isSelected
              ? "#192E49"
              : "#e1e2ea"
            : state.isSelected
            ? "#696c96"
            : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style,
        }),
      }}
    />
  );
};

SelectPriority.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  fetchUrl: PropTypes.string,
};

export default SelectPriority;

const CustomOption = (props) => {
  const { innerRef, innerProps, data, isFocused, isSelected } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`custom-option ${isFocused ? "focused" : ""} ${
        isSelected ? "selected" : ""
      }`}
      style={{
        backgroundColor: isSelected
          ? "#696c96"
          : isFocused
          ? "#e1e2ea"
          : "inherit",
        color: isSelected ? "white" : "black",
        display: "flex",
        alignItems: "center",
        padding: 10,
      }}
    >
      <FaCircle
        className="me-1"
        color={getPriorityWithProps(data.value)}
        size={10}
      />
      <span>{data.label}</span>
    </div>
  );
};
