import axios from "axios";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import SelectTimeZone from "components/form/SelectTimeZone";
import { reminderFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import React, { useContext, useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { BsClockHistory } from "react-icons/bs";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import {
  IoIosMore,
  IoMdCheckmarkCircleOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { TbClockBolt, TbClockSearch } from "react-icons/tb";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { showToast } from "../Toast/toast";
import {
  AuthWizardContext,
  DeleteContext,
  StoreContext,
} from "context/Context";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "../ListLoading";
import AppPagination from "components/common/app-pagination/AppPagination";
import { MODULES } from "constants/Constants";
import { CustomBackButton } from "../Buttons/AppButtons";

const ReminderForm = ({ show, details, onHide, viewList, data }) => {
  const [queryParams] = useSearchParams();
  const sourceId = queryParams.get("source_id");
  let source = queryParams.get("source");
  let reminder_id = queryParams.get("reminder_id");
  const view = queryParams.get("view") || viewList;
  const { overviewId, itemId, itemInfoId, client_id, empId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(reminderFormKeys);
  const [formError, setFormError] = useState({});
  const { user } = useContext(AuthWizardContext);
  const [reminders, setReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const [onSave, setOnSave] = useState(false);
  const location = useLocation();
  const { pathname } = useLocation();
  const accessLocation = location.pathname?.split("/")[1];
  const activeRoute =
    accessLocation === MODULES.LEAD
      ? `/lead/info/${overviewId}`
      : accessLocation === MODULES.OPPORTUNITY
      ? `/opportunity/overview/${itemId}`
      : accessLocation === MODULES.TODO
      ? `/todo`
      : accessLocation === MODULES.PROJECT
      ? `/project/info/${itemInfoId}`
      : accessLocation === MODULES.SPRINT
      ? `/sprints/info/${itemInfoId}`
      : accessLocation === MODULES.ACCOUNT
      ? `/account/overview/${client_id}`
      : accessLocation === MODULES.EMPLOYEE
      ? `/employee/overview/${empId}`
      : pathname.includes("tasks/list/overview")
      ? `/tasks/list/overview/${overviewId}`
      : accessLocation === MODULES.TASKS
      ? `/tasks/list`
      : "";
  const { store, addIitemToStore } = useContext(StoreContext);
  const triggerReminderTab = store?.triggerReminderTab ?? false;

  const [fetchData] = useOutletContext() || [];
  source = source ? source : details?.source ? details?.source : "";
  reminder_id = reminder_id
    ? reminder_id
    : details?.reminder_id
    ? details?.reminder_id
    : "";

  const handleClose = () => {
    setFormData(reminderFormKeys);
    delete allQueryParams?.reminder_id;
    delete allQueryParams?.view;
    delete allQueryParams?.source;
    delete allQueryParams?.source_id;
    if (show) {
      onHide();
    } else {
      navigate(
        `${activeRoute}?${createSearchParams({
          ...allQueryParams,
        })}`
      );
    }
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      let value;
      if (e.target.name === "is_repeating") {
        value = e.target.checked ? 1 : 0;
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);
    const { id, ...formDataWithoutId } = formDataWithoutRefkeys;

    axios({
      method: reminder_id || formData?.id ? "put" : "post",
      url: `crm/reminders${
        reminder_id || formData?.id ? "/" + (reminder_id || formData?.id) : ""
      }`,
      data: formDataWithoutId,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          setFormData({
            ...reminderFormKeys,
            user_id: user.id,
            time_zone: user.time_zone,
            time_zone_ref: { label: user.time_zone, value: user.time_zone },
            entity_type: source,
            entity_id: sourceId || details?.source_id,
          });
          if (getData && typeof getData === "function") getData();
          if (fetchData && typeof fetchData === "function") fetchData();
          addIitemToStore("triggerReminderTab", !triggerReminderTab);
          if (view && view === "table") {
            navigate(-1);
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          const validation_error = error?.response?.data?.data?.errors
            ? error?.response?.data?.data?.errors
            : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/reminders",
      params: {
        page: page,
        entity_type: source,
        entity_id: sourceId || details?.source_id,
      },
    });
    setReminders(data);
    setIsLoading(false);
  };

  const getParticularData = async () => {
    setIsDataLoading(true);
    const data = await apiCall({
      url: `crm/reminders/${reminder_id}`,
      params: {},
    });
    setFormData({
      id: data?.id,
      entity_type: data?.entity_type,
      entity_id: data?.entity_id,
      user_id: data?.user_id,
      frequency: data?.frequency,
      interval: data?.interval,
      remarks: data?.remarks,
      reminder_date_time: data?.next_reminder_date_time,
      time_zone: data?.time_zone,
      time_zone_ref: { label: data?.time_zone, value: data?.time_zone },
      is_repeating: data?.is_repeating === true ? 1 : 0,
    });
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (reminder_id) {
      getParticularData();
    }
    if (!view || view !== "table" || show) {
      getData(pageNumber);
    }
    setFormData((prev) => ({
      ...prev,
      user_id: user.id,
      time_zone: user.time_zone,
      time_zone_ref: { label: user.time_zone, value: user.time_zone },
      entity_type: source,
      entity_id: sourceId || details?.source_id,
    }));

    if (data && Object.keys(data).length > 0) {
      setFormData({
        id: data?.id,
        entity_type: data?.entity_type,
        entity_id: data?.entity_id,
        user_id: data?.user_id,
        frequency: data?.frequency,
        interval: data?.interval,
        remarks: data?.remarks,
        reminder_date_time: data?.next_reminder_date_time,
        time_zone: data?.time_zone,
        time_zone_ref: { label: data?.time_zone, value: data?.time_zone },
        is_repeating: data?.is_repeating === true ? 1 : 0,
      });
    }

    return () => {
      setFormData(reminderFormKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, reminder_id, show, data]);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/reminders/" + id,
        title: "Delete Reminder",
        message: "Are you sure you want to delete this reminder?",
        onSuccess: () => {
          const dataWithoutDeletedItem = reminders.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            navigate(
              `${activeRoute}/reminder?${createSearchParams({
                ...allQueryParams,
                page: pageNumber - 1,
              })}`
            );
          } else {
            setReminders((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const handleEdit = (data) => {
    setFormData({
      id: data?.id,
      entity_type: data?.entity_type,
      entity_id: data?.entity_id,
      user_id: data?.user_id,
      frequency: data?.frequency,
      interval: data?.interval,
      remarks: data?.remarks,
      reminder_date_time: data?.next_reminder_date_time,
      time_zone: data?.time_zone,
      time_zone_ref: { label: data?.time_zone, value: data?.time_zone },
      is_repeating: data?.is_repeating === true ? 1 : 0,
    });
  };

  const handleMarkAsDone = (id) => {
    if (id) {
      axios
        .put(`crm/reminder/mark-as-done`, {
          id: id,
        })
        .then((response) => {
          if (response.data.success === true) {
            showToast(response.data.message, "success");
            getData();
            if (fetchData && typeof fetchData === "function") fetchData();
          }
        })
        .catch((error) => {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        });
    }
  };

  return (
    <Modal
      size={view && view === "table" ? "md" : "lg"}
      show={show ? show : sourceId ? true : false}
      aria-labelledby="contained-modal-title-vcenter"
      className="no-border-radius-modal"
    >
      <Modal.Body className="pt-0 pb-0">
        <Row>
          <Col className="p-0">
            <Modal.Header className="d-flex justify-content-start align-items-center gap-2">
              <TbClockBolt size={21} color="#000" />
              <span className="fw-bold text-dark">
                {reminder_id || formData?.id ? "Update" : "New"} Reminder
              </span>
            </Modal.Header>
            {isDataLoading && view === "table" ? (
              <ListLoading
                style={{
                  height: "51vh",
                  overflow: "hidden",
                }}
              />
            ) : (
              <Form className="p-3">
                <Form.Group className="mb-3">
                  <Form.Label className="require-data">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="remarks"
                    onChange={handleFieldChange}
                    value={formData.remarks}
                    isInvalid={!!formError.remarks}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.remarks}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="require-data">Timezone</Form.Label>
                  <SelectTimeZone
                    name="time_zone"
                    handleFieldChange={handleFieldChange}
                    value={formData.time_zone_ref}
                    error={formError.time_zone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.time_zone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="require-data">
                    Reminder Date Time
                  </Form.Label>
                  <AppTimePicker
                    name="reminder_date_time"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    hourPlaceholder="hh"
                    minutePlaceholder="mm"
                    onChange={handleFieldChange}
                    value={formData.reminder_date_time}
                    isInvalid={!!formError.reminder_date_time}
                  />
                  <Form.Control.Feedback type="is-invalid">
                    {formError.reminder_date_time}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Repeat</Form.Label>
                  <Form.Check
                    type="switch"
                    className="custom-common-switch mt-1"
                    id="custom-switch"
                    size={"sm"}
                    name="is_repeating"
                    onChange={handleFieldChange}
                    checked={formData.is_repeating}
                  />
                </Form.Group>
                {formData.is_repeating ? (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label className="require-data">Interval</Form.Label>
                      <Form.Control
                        type="number"
                        name="interval"
                        onChange={handleFieldChange}
                        value={formData.interval}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="require-data">
                        Frequency
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        size="md"
                        name="frequency"
                        onChange={handleFieldChange}
                        value={formData.frequency}
                      >
                        <option value="">Select</option>
                        <option value="minute">Minute</option>
                        <option value="hour">Hour</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                      </Form.Select>
                    </Form.Group>
                  </>
                ) : null}

                <Form.Group className="mb-3 d-flex justify-content-end gap-2">
                  <Button size="sm" variant="danger" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={handleSubmit}
                    disabled={onSave}
                  >
                    {onSave ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <>{reminder_id || formData?.id ? "Update" : "Save"}</>
                    )}
                  </Button>
                </Form.Group>
              </Form>
            )}
          </Col>
          {(!view || view !== "table") && (
            <Col lg={7} className="border-start p-0">
              <div className="d-flex align-items-center justify-content-between p-3 border-bottom">
                <div className="d-flex justify-content-start align-items-center gap-2">
                  <BsClockHistory size={18} />
                  <span className="fw-bold">Reminders List</span>
                </div>
                <CustomBackButton
                  variant="falcon-default"
                  title="Close"
                  onClick={handleClose}
                  className={"ms-2"}
                />
              </div>
              {reminders?.data?.length > 0 && !isLoading ? (
                <>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Next Reminder on</th>
                        <th>Repeat</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reminders.data.map((reminder, key) => (
                        <tr>
                          <td>{key + 1}</td>
                          <td
                            className="text-truncate"
                            style={{ maxWidth: "7vw" }}
                          >
                            {reminder.remarks}
                          </td>
                          <td>{reminder.next_reminder_date_time_formatted}</td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Badge
                                bg={
                                  reminder.is_repeating ? `success` : `danger`
                                }
                              >
                                {reminder.is_repeating ? "Yes" : "No"}
                              </Badge>
                              {reminder.is_repeating && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Popover id="popover-basic">
                                      <Popover.Header
                                        as="h3"
                                        className="fw-bold"
                                      >
                                        More Information
                                      </Popover.Header>
                                      <Popover.Body>
                                        <div className="fs--1">
                                          <span>Timezone : </span>
                                          <span>
                                            {reminder?.time_zone
                                              ? reminder?.time_zone
                                              : "N/A"}
                                          </span>
                                        </div>
                                        <div className="fs--1">
                                          <span>Repeat Interval : </span>
                                          <span>
                                            {reminder?.interval
                                              ? reminder?.interval +
                                                " " +
                                                reminder?.frequency
                                              : "N/A"}
                                          </span>
                                        </div>
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <Button
                                    size="sm"
                                    variant="transparent"
                                    className="p-0 m-0 custom-transperent-common-button"
                                  >
                                    <IoMdInformationCircleOutline
                                      size={18}
                                      color="#269cfd"
                                    />
                                  </Button>
                                </OverlayTrigger>
                              )}
                            </div>
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-basic-button"
                              className="custom-dropdown-button custom-transperent-common-button"
                              variant="transparent"
                              title={<IoIosMore size={20} />}
                              size="sm"
                            >
                              <Dropdown.Item
                                onClick={() => handleMarkAsDone(reminder.id)}
                              >
                                <div className="d-flex align-items-center">
                                  <IoMdCheckmarkCircleOutline
                                    size={20}
                                    color="#37b137"
                                  />
                                  <span className="ms-1">Mark as done</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(reminder.id)}
                              >
                                <div className="d-flex align-items-center">
                                  <FaRegTrashAlt size={17} color="#f16969" />
                                  <span className="ms-1">Delete</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleEdit(reminder)}
                              >
                                <div className="d-flex align-items-center">
                                  <FaEdit color="#f18615cc" size={17} />
                                  <span className="ms-1">Edit</span>
                                </div>
                              </Dropdown.Item>
                            </DropdownButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-end align-items-end mt-auto">
                    {reminders && reminders.links && (
                      <AppPagination
                        data={reminders}
                        url={`${activeRoute}/reminder`}
                        search={allQueryParams}
                      />
                    )}
                  </div>
                </>
              ) : isLoading ? (
                <ListLoading
                  style={{
                    height: "51vh",
                    overflow: "hidden",
                  }}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center h-75">
                  <TbClockSearch size={25} className="me-2" />
                  No reminders found
                </div>
              )}
            </Col>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ReminderForm;
