import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { AiFillPlusCircle } from "react-icons/ai";
import {
  createSearchParams,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import MeetingsForm from "./Form/MeetingsForm";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import { DeleteContext } from "context/Context";
import useAxisproPermission from "hooks/useAxisproPermission";
import {
  DeleteButton,
  EditButton,
  ReminderButton,
} from "module/common/Buttons/AppButtons";
import SimpleBar from "simplebar-react";
import ShowMore from "components/common/ShowMore";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import ReminderForm from "module/common/Reminder/ReminderForm";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import CommonStatusUpdateDropDown from "components/common/DropDown/CommonStatusUpdateDropDown";

function Meetings({ type, itemId, overview }) {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { overviewId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const location = useLocation();
  const { dispatch } = useContext(DeleteContext);
  const axisProPermission = useAxisproPermission();
  const [statusLoading, setStatusLoading] = useState(false);
  const permissionObject = {
    lead: {
      store_meeting: axisProPermission("store-lead-meeting") && type === "LEAD",
      update_meeting:
        axisProPermission("update-lead-meeting") && type === "LEAD",
      delete_meeting:
        axisProPermission("delete-lead-meeting") && type === "LEAD",
    },
    opportunity: {
      store_meeting:
        axisProPermission("store-opportunity-meeting") &&
        type === "OPPORTUNITY",
      update_meeting:
        axisProPermission("update-opportunity-meeting") &&
        type === "OPPORTUNITY",
      delete_meeting:
        axisProPermission("delete-opportunity-meeting") &&
        type === "OPPORTUNITY",
    },
  };
  const [reminderDetails, setReminderDetails] = useState({
    show: false,
    details: {
      source: "",
      source_id: "",
      reminder_id: "",
    },
  });

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/meetings/" + id,
        title: "Delete Meeting",
        message: "Are you sure you want to delete this meeting?",
        onSuccess: () => {
          fetchData();
        },
      },
    });
  };

  const handleReminder = (id) => {
    if (type === "LEAD") {
      setReminderDetails({
        show: true,
        details: {
          source: "meeting",
          source_id: id,
          reminder_id: "",
        },
      });
    } else {
      navigate(
        `${location.pathname}/reminder?${createSearchParams({
          ...allQueryParams,
          source: "meeting",
          source_id: id,
        })}`
      );
    }
  };

  const columns = [
    {
      width: "12.5%",
      accessor: "title",
      Header: Translate("TITLE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2 text-uppercase",
      },
      Cell: (rowData) => {
        const { title, id } = rowData.row.original;
        return (
          <ShowMore
            content={title ? title : "N/A"}
            contentLimit={20}
            uniqueKey={id}
          />
        );
      },
    },
    {
      width: "12.5%",
      accessor: "start_time_formatted",
      Header: Translate("START TIME"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { start_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{start_time_formatted}</h5>;
      },
    },
    {
      width: "12.5%",
      accessor: "end_time_formatted",
      Header: Translate("END TIME"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { end_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{end_time_formatted}</h5>;
      },
    },
    {
      width: "12.5%",
      accessor: "related_to_object.first_name",
      Header: Translate("HOST NAME"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { host } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{host?.name}</h5>;
      },
    },
    {
      width: "12.5%",
      accessor: "related_to_object.phone_no",
      Header: Translate("HOST PHONE"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { host } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{host?.phone}</h5>;
      },
    },
    {
      width: "12.5%",
      accessor: "location",
      Header: Translate("LOCATION"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { location } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{location}</h5>;
      },
    },
    {
      width: "12.5%",
      accessor: "status",
      Header: Translate("STATUS"),
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { status, id } = rowData.row.original;
        return (
          <CommonStatusUpdateDropDown
            getData={fetchData}
            activeStatus={status}
            postParams={{ id: id }}
            fetchUrl="crm/meeting-call-status"
            method="patch"
            postUrl="crm/meetings/update-status"
            setLoading={setStatusLoading}
          />
        );
      },
    },
    {
      width: "12.5%",
      accessor: "none",
      Header: "",
      headerProps: {
        className: [
          permissionObject.lead.update_meeting,
          permissionObject.opportunity.update_meeting,
          permissionObject.lead.delete_meeting,
          permissionObject.opportunity.delete_meeting,
          type === "CUSTOMER",
        ].some(Boolean)
          ? ""
          : "d-none",
      },
      disableSortBy: true,
      cellProps: {
        className: [
          permissionObject.lead.update_meeting,
          permissionObject.opportunity.update_meeting,
          permissionObject.lead.delete_meeting,
          permissionObject.opportunity.delete_meeting,
          type === "CUSTOMER",
        ].some(Boolean)
          ? "text-end"
          : "text-end d-none",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <>
            <ReminderButton handleReminder={handleReminder} id={id} />
            <div className="d-flex gap-2">
              {(permissionObject.lead.update_meeting ||
                permissionObject.opportunity.update_meeting ||
                type === "CUSTOMER") && (
                <EditButton
                  route={`?${createSearchParams({
                    tab: "meeting",
                    add_meeting: true,
                    edit_meeting_id: id,
                  })}`}
                />
              )}
              {(permissionObject.lead.delete_meeting ||
                permissionObject.opportunity.delete_meeting ||
                type === "CUSTOMER") && (
                <DeleteButton onClick={() => handleDelete(id)} />
              )}
            </div>
          </>
        );
      },
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/meetings",
      params: {
        related_to_type:
          type === "LEAD" || overview === "lead"
            ? "LEAD"
            : type === "CUSTOMER"
            ? "CUSTOMER"
            : "OPPORTUNITY",
        related_to_id: itemId,
      },
    });
    setData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
    <>
      {type === "CUSTOMER" && <Outlet context={[fetchData]} />}
      {!isLoading ? (
        <Row className={overview ? "m-0" : ""}>
          <Col xs={12} className={overview ? "p-0" : ""}>
            <Card className="flex-fill shadow-none">
              <Card.Header
                className={`rounded-0 pb-2 pt-2`}
                style={{
                  backgroundColor: "#5f6f83",
                  color: "#fff",
                }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="ms-3">{Translate("MEETING INFORMATION")}</div>
                  {((!overviewId && itemId) || type === "LEAD") && (
                    <MeetingsForm getData={fetchData} type={type} id={itemId} />
                  )}
                  {(permissionObject.lead.store_meeting ||
                    permissionObject.opportunity.store_meeting ||
                    type === "CUSTOMER") && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(
                          `?${createSearchParams({
                            ...allQueryParams,
                            add_meeting: true,
                          })}`
                        );
                      }}
                      title="Add Meeting"
                    >
                      <AiFillPlusCircle size={22} className={`text-light`} />
                    </div>
                  )}
                </div>
              </Card.Header>
              <Card.Body
                style={{
                  height:
                    type === "OPPORTUNITY"
                      ? "36.8rem"
                      : overview
                      ? "41rem"
                      : "41rem",
                }}
                className={`p-0 rounded-0`}
              >
                {data.length > 0 ? (
                  <div className="mt-3">
                    <SimpleBar className="simplebar-style-with-pagination-and-filter-on-with-three-row simplebar-custom">
                      <Card.Body className="p-0">
                        <AdvanceTableWrapper
                          columns={columns}
                          data={data}
                          sortable
                        >
                          <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableprops={{
                              size: "sm",
                              striped: true,
                              className: "fs--1 mb-0 overflow-hidden ",
                            }}
                          />
                        </AdvanceTableWrapper>
                      </Card.Body>
                    </SimpleBar>
                  </div>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    {Translate("No meeting information! 🙂")}
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <ListLoading style={{ height: "75vh", overflow: "hidden" }} />
      )}
      {reminderDetails?.show && (
        <ReminderForm
          show={reminderDetails?.show}
          details={reminderDetails?.details}
          onHide={() => setReminderDetails({ show: false, details: {} })}
        />
      )}
      {statusLoading && <LoadingOverlay />}
    </>
  );
}

Meetings.propTypes = {
  data: PropTypes.any,
};

export default Meetings;
