import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Badge, Form } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";

const PillTextInput = ({
  type = "",
  name,
  value,
  isDisabled,
  isInvalid,
  onChange,
  ...rest
}) => {
  const [selectedValues, setSelectedValues] = useState(value ?? "");
  const [currentValue, setCurrentValue] = useState("");
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value.endsWith(" ") || value.endsWith(",")) {
      const trimmedValue = value.trim();
      if (trimmedValue) {
        const newPillValue = trimmedValue.replace(/,$/, "");
        const updatedValues = selectedValues
          ? `${selectedValues},${newPillValue}`
          : newPillValue;

        setSelectedValues(updatedValues);
        setCurrentValue("");

        if (onChange && typeof onChange === "function") {
          onChange({
            target: {
              name,
              value: updatedValues,
            },
          });
        }
      } else {
        setCurrentValue("");
      }
    } else {
      setCurrentValue(value);
    }
  };

  const handlePillClick = (pillValue) => {
    const updatedValues = selectedValues
      .split(",")
      .filter((v) => v !== pillValue)
      .join(",");
    setSelectedValues(updatedValues);

    if (onChange && typeof onChange === "function") {
      onChange({
        target: {
          name,
          value: updatedValues,
        },
      });
    }
  };

  useEffect(() => {
    if (value) {
      setSelectedValues(value);
    } else if (!value) {
      setSelectedValues("");
      setCurrentValue("");
    }
  }, [value]);

  const blockInvalidChars = (event) => {
    const allowedKeys = [
      "Backspace",
      ",",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      " ",
    ];
    const isNumber = /^[0-9]$/;

    if (type === "number") {
      if (!allowedKeys.includes(event.key) && !isNumber.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Backspace") {
      if (selectedValues) {
        const valuesArray = selectedValues.split(",");
        valuesArray.pop();
        const updatedValues = valuesArray.join(",");
        setSelectedValues(updatedValues);

        if (onChange && typeof onChange === "function") {
          onChange({
            target: {
              name,
              value: updatedValues,
            },
          });
        }
      }
    } else if (event.key === "v" && (event.ctrlKey || event.metaKey)) {
      return;
    }

    blockInvalidChars(event);
  };

  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData("text");
    const validNumbers = pastedData
      .split(",")
      .map((v) => v.trim())
      .filter((v) => /^[0-9]*$/.test(v));

    if (validNumbers.length > 0) {
      const newValues = validNumbers.join(",");
      setSelectedValues((prev) => {
        const updatedValues = prev ? `${prev},${newValues}` : newValues;
        if (onChange && typeof onChange === "function") {
          onChange({
            target: {
              name,
              value: updatedValues,
            },
          });
        }
        return updatedValues;
      });
    }

    event.preventDefault();
  };

  const createBadge = () => {
    if (currentValue.trim()) {
      const newPillValue = currentValue.trim().replace(/,$/, "");
      const updatedValues = selectedValues
        ? `${selectedValues},${newPillValue}`
        : newPillValue;

      setSelectedValues(updatedValues);
      setCurrentValue("");

      if (onChange && typeof onChange === "function") {
        onChange({
          target: {
            name,
            value: updatedValues,
          },
        });
      }
    }
  };

  return (
    <div className="pill-text-input">
      <div
        className="pill-text-input-container p-2 py-1 gap-1"
        style={{
          minHeight: "31px",
          position: "relative",
          maxHeight: "90px",
          overflow: "auto",
        }}
        onClick={() => inputRef.current.focus()}
        onMouseLeave={createBadge}
      >
        {selectedValues.split(",").map(
          (value, index) =>
            value && (
              <Badge
                key={index}
                className="pill-text-input-badge px-2 py-1 text-dark"
                bg="none"
              >
                {value}
                <IoCloseSharp
                  onClick={() => handlePillClick(value)}
                  className="ms-2 cursor-pointer"
                  size={12.5}
                />
              </Badge>
            )
        )}

        <Form.Control
          type="text"
          name={name}
          className="border-0 flex-grow-1"
          value={currentValue}
          isInvalid={isInvalid}
          disabled={isDisabled}
          onChange={handleInputChange}
          ref={inputRef}
          onKeyDown={handleOnKeyDown}
          onPaste={handlePaste}
          onBlur={createBadge}
          {...rest}
        />
      </div>
    </div>
  );
};

PillTextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isInvalid: PropTypes.any,
  value: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default PillTextInput;
