import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { AddAssigneeFormKeys } from "helpers/formKeys";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import SelectUsers from "components/form/SelectUsers";
import BackButton from "components/common/BackButton";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import { TbEditCircle } from "react-icons/tb";
import { CustomBackButton } from "module/common/Buttons/AppButtons";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import { StoreContext } from "context/Context";

const BulkUpdateModal = ({
  show,
  getData,
  handleClose,
  selectedTasksId,
  permission,
  canUpdateAllStatuses,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(AddAssigneeFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");
  const [status, setStatus] = useState("none");
  const [isLoading, setIsLoading] = useState(false);
  const [statusArray, setStatusArray] = useState([]);
  const { store, addIitemToStore } = useContext(StoreContext);

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.name);
  };

  const updateAssignee = () => {
    const data = {
      task_id: selectedTasksId,
      assignee: formData.assinee,
    };
    return axios.post("crm/task-bulk-assignee-update", data);
  };

  const getStatusData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/task-status",
    });
    setStatusArray(data);
    addIitemToStore("taskStatus", data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (show && store?.taskStatus?.length === 0) {
      getStatusData();
    } else {
      setStatusArray(store?.taskStatus);
    }

    return () => {
      setFormError({});
      setStatusArray([]);
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const updateStatus = () => {
    const data = {
      task_id: selectedTasksId,
      status: status,
    };
    return axios.post("crm/task-bulk-status-update", data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSave) return;
    setFormError({});
    setOnSave(true);

    try {
      const updates = [];

      if (formData.assinee !== "") {
        updates.push(updateAssignee());
      }

      if (status !== "none") {
        updates.push(updateStatus());
      }

      if (updates.length !== 0) {
        const results = await Promise.all(updates);

        if (results.every((result) => result.data.success)) {
          getData(pageNumber);
          handleCloseBulkModal();
          showToast("Bulk Update successful", "success");
        } else {
          const errorMessages = results
            .filter((result) => !result.success)
            .map((result) => result.message);
          showToast(`Error: ${errorMessages.join(", ")}`, "error");
        }
      }
    } catch (error) {
      if (error.response.data && error.response.data.message) {
        const validation_error =
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.errors
            ? error.response.data.data.errors
            : null;
        validation_error && setFormError({ ...validation_error });

        if ("new_assignee" in validation_error) {
          showToast(validation_error.new_assignee[0], "error");
        } else {
          showToast(error?.response?.data?.message, "error");
        }
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
      }
    } finally {
      setOnSave(false);
    }
  };

  const handleCloseBulkModal = () => {
    handleClose ? handleClose() : navigate(-1);
    setStatus("none");
    setFormData(AddAssigneeFormKeys);
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title className="d-flex  align-items-center">
          <TbEditCircle size={20} className="me-2 text-dark" />
          <div className="" style={{ fontSize: "16px" }}>
            Bulk Update
          </div>
        </Modal.Title>
        <CustomBackButton
          variant="falcon-default"
          title="Close"
          onClick={handleCloseBulkModal}
          className={"ms-2"}
        />
      </Modal.Header>
      {isLoading ? (
        <ListLoading style={{ height: "300px", overflow: "hidden" }} />
      ) : statusArray?.length > 0 ? (
        <Modal.Body>
          <Form>
            {permission?.updateTaskAssignee && (
              <Row>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Group>
                    <Form.Label>Assignee</Form.Label>
                    <SelectUsers
                      name="assinee"
                      value={formData.assinee_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError.assinee}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.assinee}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Group>
              </Row>
            )}
            {canUpdateAllStatuses && (
              <Row className="gx-3 p-1 pt-2 pb-2 border-top">
                <span className="text-dark mb-2">Status</span>
                {statusArray.map((el, key) => (
                  <Col sm={4} xs={6} key={key} className="mb-2 ">
                    <div className="d-flex align-items-center">
                      <input
                        type="radio"
                        name={el}
                        className="me-1"
                        style={{ cursor: "pointer" }}
                        checked={status === el}
                        onChange={handleStatusChange}
                      />
                      <span className="me-1">
                        {getTaskStatusWithProps(el, "icon", 16)}
                      </span>
                      <span className="text-capitalize text-dark">
                        {el.replace("_", " ")}
                      </span>
                    </div>
                  </Col>
                ))}
                <Col sm={4} xs={6} className="mb-2">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="none"
                      className="me-1"
                      style={{ cursor: "pointer" }}
                      checked={status === "none"}
                      onChange={handleStatusChange}
                    />
                    <span className="text-capitalize text-dark fw-bold">
                      None
                    </span>
                  </div>
                </Col>
              </Row>
            )}
            <Form.Group className="d-flex justify-content-end gap-1 mt-3">
              <SaveButton
                id={true}
                handleSubmit={handleSubmit}
                onSave={onSave}
              />
              {!onSave && (
                <>
                  {handleClose ? (
                    <Button
                      variant={"danger"}
                      size={"sm"}
                      onClick={handleCloseBulkModal}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <BackButton variant={"danger"} size={"sm"}>
                      Cancel
                    </BackButton>
                  )}
                </>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
      ) : (
        <></>
      )}
    </Modal>
  );
};

BulkUpdateModal.prototype = {
  show: PropTypes.bool,
  getData: PropTypes.func,
};

export default BulkUpdateModal;
