import { React, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import { AuthWizardContext } from "context/Context";
import Avatar from "components/common/Avatar";
import { showToast } from "module/common/Toast/toast";
import defaultImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";
import { MdLockReset } from "react-icons/md";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import useIsUserProfilePage from "hooks/useIsUserProfilePage";

const ProfileDropdown = () => {
  let navigate = useNavigate();
  const { dispatch, user } = useContext(AuthWizardContext);
  let loggedAsCustomer = user?.customer_id ? true : false;
  const loggedUserIsEmployee = user?.employee_id ? true : false;
  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
    setLoading(true);
    axios
      .post("auth/logout")
      .then((res) => {
        if (res.data.success === true) {
          // remove user data from localStorage
          localStorage.removeItem("AXIS_PRO_CRM_USER");
          //removing user based permmisions
          localStorage.removeItem("ACODAX_CRM_PERMISSION");
          // delete user details from context
          dispatch({ type: "DELETE" });
          showToast(res.data.message, "success");
          // redirect user to login screen
          navigate(
            `/authentication/login?as=${loggedAsCustomer ? "client" : "user"}`
          );
          setLoading(false);
          localStorage.removeItem("pending_task_data");
          localStorage.setItem(
            "show_pending_task_modal",
            JSON.stringify(false)
          );
          localStorage.removeItem("lastApiCallTime");
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          // remove user data from localStorage
          localStorage.removeItem("AXIS_PRO_CRM_USER");
          //removing user based permmisions
          localStorage.removeItem("ACODAX_CRM_PERMISSION");
          // delete user details from context
          dispatch({ type: "DELETE" });
          // redirect user to login screen
          navigate(
            `/authentication/login?as=${loggedAsCustomer ? "client" : "user"}`
          );
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        setLoading(false);
      });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    axios({
      method: "post",
      url: `send-customer-password-reset-link`,
      params: {
        username: user?.username,
        app_name: `CRM`,
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          setLoading(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          if ("email" in validation_error) {
            showToast(validation_error.email[0], "error");
          } else {
            showToast(error.response.data.message, "error");
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }

        setLoading(false);
      });
  };

  const isUserProfilePage = useIsUserProfilePage();
  const navigateUserToProfilePage = () => {
    if (!loggedUserIsEmployee || isUserProfilePage) return;
    navigate(`/user-profile/?tab=gi`);
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <Dropdown navbar={true} as="li" className="me-2">
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          className="custom-transperent-common-button p-0 shadow-none d-flex"
          title="User Profile"
        >
          {<Avatar src={user?.image ?? defaultImage} />}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item
              className={`d-flex gap-1 align-items-center ${
                !loggedUserIsEmployee || isUserProfilePage ? "pointer-none" : ""
              }`}
              onClick={navigateUserToProfilePage}
            >
              <RiAccountPinBoxFill size={23} />
              {user?.name ?? ""}
            </Dropdown.Item>
            <Dropdown.Divider />
            {loggedAsCustomer && (
              <>
                <Dropdown.Item
                  className="d-flex gap-1 align-items-center"
                  onClick={handleResetPassword}
                >
                  <MdLockReset size={23} />
                  Reset Password
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item
              className="d-flex gap-1 align-items-center"
              onClick={handleLogout}
            >
              <BiLogOut size={23} />
              Logout
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileDropdown;
