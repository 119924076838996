import { React, useState, useEffect, useContext } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { RiTimerFlashLine } from "react-icons/ri";
import { TimerButton } from "../Buttons/AppButtons";
import { StoreContext } from "context/Context";

function DigitalTimer({
  startTime,
  handleButtonVisibility,
  loadingTimerButton = false,
  timerRunning = false,
  timerButtonVisibility = true,
  data,
}) {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [dayElapsed, setDayElapsed] = useState(0);

  const { store, addIitemToStore } = useContext(StoreContext);
  const runningTaskData = store?.runningTaskData || {};

  const runningTaskDataFromLocalStorage =
    JSON.parse(localStorage.getItem("runningTaskData") || "{}") ||
    runningTaskData ||
    {};

  useEffect(() => {
    if (startTime && data?.id) {
      if (runningTaskDataFromLocalStorage?.id === data?.id) return;

      const dataToLocalStorage = {
        id: data?.id,
        project_id: data?.project_id,
        created_by: data?.created_by,
        start_time: data?.current_time_tracker[0]?.start_time,
        task_number: data?.task_number,
        title: data?.title,
        priority: data?.priority,
        total_time_tracked: data?.total_time_tracked,
        project_title: data?.project_title,
        created_at: data?.created_at,
        created_user_full_name: data?.created_user_full_name,
        running_status: data?.running_status,
      };

      addIitemToStore("runningTaskData", dataToLocalStorage);
      localStorage.setItem(
        "runningTaskData",
        JSON.stringify(dataToLocalStorage)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  useEffect(() => {
    if (startTime) {
      const startDateTime = new Date(startTime + " UTC");
      const interval = setInterval(() => {
        const currentTime = new Date();
        const elapsedDays = Math.floor(
          (currentTime - startDateTime) / (1000 * 60 * 60 * 24)
        );
        setDayElapsed(elapsedDays);
        const elapsedTime = currentTime - startDateTime;
        setTimeElapsed(elapsedTime);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setDayElapsed(0);
      setTimeElapsed(0);
    }
  }, [startTime]);

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <Card>
      <Card.Body className="p-0 rounded-0 shadow border">
        <ListGroup horizontal>
          <ListGroup.Item
            className={`rounded-0 pt-0 pb-0 ps-2 pe-2 custom-digital-timer border-0 ${
              dayElapsed === 0 ? "custom-digital-timer-day-wrapper" : ""
            }`}
          >
            <RiTimerFlashLine
              size={20}
              color={dayElapsed === 0 ? " #fff" : "#2b3139"}
            />
          </ListGroup.Item>
          {startTime && (
            <>
              {dayElapsed > 0 && (
                <ListGroup.Item className="rounded-0 pt-2 pb-0 ps-0 pe-2 custom-digital-timer custom-digital-timer-day-wrapper border-0">
                  <div className="timer-container d-flex flex-column justify-content-center align-items-center ps-2">
                    <span
                      className="fw-bolder custom-digital-timer-day"
                      style={{ fontSize: "12px", lineHeight: "9px" }}
                    >
                      {dayElapsed}
                    </span>
                    <span
                      className="text-muted custom-digital-timer-day-header"
                      style={{ fontSize: "10px" }}
                    >
                      {dayElapsed > 1 ? "DAYS" : "DAY"}
                    </span>
                  </div>
                </ListGroup.Item>
              )}
              <ListGroup.Item className="rounded-0 pt-2 pb-0 ps-2 pe-2 custom-digital-timer border-0">
                <div className="timer-container d-flex flex-column justify-content-center align-items-center">
                  <span
                    className="fw-bolder"
                    style={{ fontSize: "12px", lineHeight: "9px" }}
                  >
                    {formatTime(timeElapsed)}
                  </span>
                  <span className="text-muted" style={{ fontSize: "10px" }}>
                    HOURS
                  </span>
                </div>
              </ListGroup.Item>
            </>
          )}
          {timerButtonVisibility && (
            <ListGroup.Item
              className={`rounded-0 ${
                startTime ? "pt-0 pb-0 ps-2 pe-2" : "p-1"
              } custom-digital-timer custom-digital-timer-button-wrapper  border-start border-0 `}
            >
              <TimerButton
                variant={"danger"}
                handleTimer={handleButtonVisibility}
                loadingTimer={loadingTimerButton}
                title={"Stop Timer"}
                timerRunning={timerRunning}
              />
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default DigitalTimer;
