import { React, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Dropdown, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { FaDownload, FaFileExcel, FaFilePdf } from "react-icons/fa";

import { showToast } from "module/common/Toast/toast";
import { ExportContext } from "context/Context";
import { showWarningToast } from "module/common/Toast/showWarningToast";
import formateData from "helpers/formateData";

const ExportButton = ({
  size,
  variant,
  className,
  url,
  initialMessage,
  successMessage,
  filters,
  ...rest
}) => {
  const { exportArray, dispatch } = useContext(ExportContext);
  const [isExporting, setIsExporting] = useState(false);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");

  const handleExport = (e, file_type) => {
    e.preventDefault();
    setIsExporting(true);
    showWarningToast(initialMessage);
    axios
      .get(url, {
        params: {
          mode: file_type,
          ...formateData(filters),
          ...formateData(JSON.parse(filterItems)),
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data && response.data.data) {
          // Add to export context
          dispatch({
            type: "ADD",
            payload: {
              url: url,
              file_type: file_type,
              initialMessage: initialMessage,
              successMessage: successMessage,
              ...response.data.data,
            },
          });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );

          setIsExporting(false);
        }
      })
      .catch((error) => {
        console.error(error);
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );

        setIsExporting(false);
      });
  };

  useEffect(() => {
    let exportItem = url
      ? exportArray.filter((item) => item.url === url && !item.status)
      : [];

    exportItem = exportItem.length > 0 ? exportItem[0] : null;
    // setIsExporting(exportItem ? true : false);
    if (exportItem) {
      setIsExporting(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportArray]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        disabled={isExporting}
        size={size ?? "sm"}
        variant={variant ?? "primary"}
        className={`d-flex flex-row align-items-center px-2 h-100 ${className}`}
        {...rest}
      >
        {isExporting ? (
          <>
            <Spinner
              animation="border"
              className="me-1"
              style={{ width: 12, height: 12 }}
            />
            <span className="fs--1">{"Exporting"}</span>
          </>
        ) : (
          <>
            <FaDownload className="me-1" size={10} />
            <span className="fs--0" style={{ fontSize: "11px" }}>
              {"Export"}
            </span>
          </>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#" onClick={(e) => handleExport(e, "pdf")}>
          <div className="d-flex flex-row align-items-center">
            <FaFilePdf
              className="me-1"
              size={11}
              style={{ color: "#f40f02" }}
            />
            <span className="fw-bolder mb-0 small">{"PDF"}</span>
          </div>
        </Dropdown.Item>
        <Dropdown.Item href="#" onClick={(e) => handleExport(e, "excel")}>
          <div className="d-flex flex-row align-items-center">
            <FaFileExcel
              className="me-1"
              size={11}
              style={{ color: "#1d6f42" }}
            />
            <span className="fw-bolder mb-0 small">{"Excel"}</span>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

ExportButton.propTypes = {
  variant: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  filters: PropTypes.object,
  initialMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

export default ExportButton;
