import { AuthWizardContext, StoreContext } from "context/Context";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { BiReset } from "react-icons/bi";
import { IoInformationCircleOutline } from "react-icons/io5";
import { TiTickOutline } from "react-icons/ti";
import SimpleBar from "simplebar-react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    size="sm"
    variant="falcon-default"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </Button>
));

const CustomizeColumnButton = ({ columns, fromModule }) => {
  const { store } = useContext(StoreContext);
  const {
    [`CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`]:
      customizedColumnsFromStore,
  } = store;
  const [custimizedColumns, setCustimizedColumns] = useState([]);
  const [checkAll, setcheckAll] = useState(false);
  const [customColumns, setCustomColumns] = useState(columns);
  const { addIitemToStore } = useContext(StoreContext);
  const { user } = useContext(AuthWizardContext);
  const custimizedColumnsFromStorage =
    JSON.parse(
      localStorage.getItem(`CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`)
    ) || [];

  const custimizedColumnsStorage = customizedColumnsFromStore
    ? customizedColumnsFromStore
    : custimizedColumnsFromStorage?.[0]?.[user.id]?.length > 0
    ? custimizedColumnsFromStorage[0][user.id]
    : [];

  const handleCheckUncheckAll = (e) => {
    if (e.target.checked) {
      const allColumns = customColumns.filter(
        (item) => item.accessor !== "none" && item.accessor !== "select"
      );
      setCustimizedColumns(allColumns);
    } else {
      const allColumns = customColumns.filter(
        (item) =>
          item.accessor !== "none" &&
          item.accessor !== "select" &&
          item.isMandatory
      );
      setCustimizedColumns(allColumns);
    }
    setcheckAll(e.target.checked);
  };

  const handleCheckChange = (e, column) => {
    if (e.target.name === "all") {
      handleCheckUncheckAll(e);
    } else {
      if (e.target.checked) {
        const allAccessorsOriginal = columns
          .filter(
            (item) => item.accessor !== "none" && item.accessor !== "select"
          )
          .map((item) => item.accessor);
        const allAccessorsCustimized = custimizedColumns
          .filter(
            (item) => item.accessor !== "none" && item.accessor !== "select"
          )
          .map((item) => item.accessor);

        allAccessorsCustimized.push(column.accessor);

        const isAllChecked = allAccessorsOriginal.every((item) =>
          allAccessorsCustimized.includes(item)
        );
        setcheckAll(isAllChecked);

        setCustimizedColumns([...custimizedColumns, column]);
      } else {
        setcheckAll(false);
        setCustimizedColumns(
          custimizedColumns.filter((item) => item.accessor !== column.accessor)
        );
      }
    }
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    const data = columns?.filter(
      (item) =>
        typeof item?.Header === "string" &&
        item?.Header?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setCustomColumns(data);
  };

  const getArraySortedByOrder = (array) => {
    return array.sort((a, b) => {
      return a.order - b.order; // Sorting by ascending order
    });
  };

  const handleApplyCustomizedColumns = () => {
    const customizedColumnsForStorage = custimizedColumns.map(
      (item) => item.accessor
    );

    if (!customizedColumnsForStorage.includes("none")) {
      customizedColumnsForStorage.push("none");

      if (!customizedColumnsForStorage.includes("select")) {
        customizedColumnsForStorage.push("select");
      }

      const noneColumns = columns.filter((item) => item.accessor === "none");
      const selectColumns = columns.filter(
        (item) => item.accessor === "select"
      );

      addIitemToStore(
        `CUSTOMIZED_COLUMNS_${fromModule?.toUpperCase()}`,
        getArraySortedByOrder([
          ...custimizedColumns,
          ...noneColumns,
          ...selectColumns,
        ])
      );
    } else {
      addIitemToStore(
        `CUSTOMIZED_COLUMNS_${fromModule?.toUpperCase()}`,
        getArraySortedByOrder(custimizedColumns)
      );
    }

    if (custimizedColumnsFromStorage?.length > 0) {
      const userIndex = custimizedColumnsFromStorage.findIndex(
        (column) => column[user.id]
      );

      if (userIndex !== -1) {
        custimizedColumnsFromStorage[userIndex] = {
          [user.id]: customizedColumnsForStorage,
        };
      } else {
        custimizedColumnsFromStorage.push({
          [user.id]: customizedColumnsForStorage,
        });
      }
      localStorage.setItem(
        `CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`,
        JSON.stringify(custimizedColumnsFromStorage)
      );
    } else {
      localStorage.setItem(
        `CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`,
        JSON.stringify([{ [user.id]: customizedColumnsForStorage }])
      );
    }
  };

  useEffect(() => {
    const isValidArrayOfObjects =
      Array.isArray(custimizedColumnsStorage) &&
      custimizedColumnsStorage.every((item) => typeof item === "object");

    let filteredColumns;
    let allAccessorsFromStorage;

    if (custimizedColumnsStorage?.length > 0) {
      if (isValidArrayOfObjects) {
        allAccessorsFromStorage = custimizedColumnsStorage.map(
          (item) => item.accessor
        );
      } else {
        allAccessorsFromStorage = custimizedColumnsStorage;
      }

      filteredColumns = columns.filter((item) =>
        allAccessorsFromStorage.includes(item.accessor)
      );
      const allAccessors = columns.map((item) => item.accessor);
      const isAllChecked = allAccessors.every((accessor) =>
        allAccessorsFromStorage.includes(accessor)
      );
      setcheckAll(isAllChecked);
      setCustimizedColumns(filteredColumns);
    } else {
      handleCheckUncheckAll({ target: { checked: true } });
    }

    if (custimizedColumnsStorage?.length > 0) {
      addIitemToStore(
        `CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`,
        custimizedColumnsFromStorage[0][user.id]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkActiveColumn = (accessor) => {
    let isChecked = false;
    if (accessor) {
      custimizedColumns.forEach((item) => {
        if (item.accessor === accessor) {
          isChecked = true;
        }
      });
    }
    return isChecked;
  };

  const handleRestCustomizedColumns = () => {
    setCustimizedColumns(columns);
    localStorage.removeItem(`CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`);
    addIitemToStore(`CUSTOMIZED_COLUMNS_${fromModule.toUpperCase()}`, columns);
    setcheckAll(true);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
        size="sm"
        className="h-100"
      >
        Customize Columns
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="Search"
          className="border"
          as={Form.Check}
          style={{ backgroundColor: "whitesmoke" }}
        >
          <Form.Control
            autoFocus
            className="mx-2 my-1 w-auto"
            placeholder="Type to filter..."
            onChange={handleFilterChange}
          />
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="All"
          className="border-start border-end border-bottom"
          as={Form.Check}
        >
          <div className="d-flex">
            <div className="d-flex align-items-center my-1">
              <Form.Check
                inline
                name={"all"}
                type={"checkbox"}
                onChange={handleCheckChange}
                checked={checkAll}
              />
              SELECT ALL
            </div>
          </div>
        </Dropdown.Item>
        <SimpleBar style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {customColumns?.length > 0 &&
            customColumns
              .filter(
                (item) => item.accessor !== "none" && item.accessor !== "select"
              )
              .map((column, index) => (
                <Fragment key={index}>
                  <Dropdown.Item
                    eventKey={column.Header}
                    className="border-start border-end"
                    as={Form.Check}
                  >
                    <div className="d-flex align-items-center">
                      <Form.Check
                        inline
                        name={column.accessor}
                        type={"checkbox"}
                        onChange={(e) => handleCheckChange(e, column)}
                        checked={checkActiveColumn(column.accessor)}
                        disabled={column?.isMandatory}
                      />
                      {column?.accessor !== "none" ? column?.Header : "ACTION"}
                      {column?.isMandatory && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              style={{ fontSize: "11px" }}
                              id={`tooltip-${column.accessor}`}
                            >
                              This column is mandatory
                            </Tooltip>
                          }
                        >
                          <span>
                            <IoInformationCircleOutline
                              size={14}
                              className="ms-2"
                              color="#ff8400"
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </Dropdown.Item>
                </Fragment>
              ))}
        </SimpleBar>
        <Dropdown.Item
          eventKey="Submit"
          className="custom-dropdown-footer border"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="d-flex justify-content-between">
            <Button
              size="sm"
              variant="success"
              className="ms-auto"
              onClick={handleApplyCustomizedColumns}
            >
              <TiTickOutline size={20} /> Apply
            </Button>
            <Button
              size="sm"
              variant="danger"
              className="ms-auto"
              onClick={handleRestCustomizedColumns}
            >
              <BiReset size={20} /> Reset
            </Button>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomizeColumnButton;
