import { React, useContext, useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { StoreContext } from "context/Context";

const SelectTicketStatus = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  isDisabled,
  clearable = true,
}) => {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { store, addIitemToStore } = useContext(StoreContext);

  const setItems = async (value) => {
    if (store?.ticketStatus?.length === 0) {
      setLoading(true);
      try {
        let response = await axios.get("crm/ticket-status", {
          params: { name: value },
        });
        let data = response && response.data ? response.data.data : [];
        return data;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      return store?.ticketStatus;
    }
  };

  const handleFocus = async ({ inputValue }) => {
    setDefaultOptions([]);
    const defaultOptionsData = await setItems(inputValue);
    addIitemToStore("ticketStatus", defaultOptionsData);
    const formattedOptions = defaultOptionsData.map((item) => ({
      label: item?.replaceAll("_", " "),
      value: item,
    }));
    setDefaultOptions(formattedOptions);
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions={defaultOptions}
        onFocus={handleFocus}
        isLoading={loading}
        isClearable={clearable}
        isDisabled={isDisabled}
        name={name ? name : "ticket_status"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error
            ? "form-control ps-0 py-0 is-invalid text-capitalize"
            : "text-capitalize"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            fontSize: "13px",
            borderRadius: "0px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
              ? state.isSelected
                ? "#192E49"
                : "#e1e2ea"
              : state.isSelected
              ? "#696c96"
              : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style,
          }),
        }}
      />
    </>
  );
};

SelectTicketStatus.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
};

export default SelectTicketStatus;
