import { Modal, Card, Button } from "react-bootstrap";
import React from "react";
import { CustomButton } from "module/common/Buttons/CommonButton";

function CommonConfirmPopup({ show, handleClose, onSubmit, isLoading, data }) {
  return (
    <Modal show={show} size="md">
      <Card>
        <Card.Header className="text-center">
          <h5 className="fs--1 text-uppercase text-dark fw-bold">
            {data?.title}
          </h5>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center justify-content-center p-0">
          <p className="m-0 fs--1">{data?.message}</p>
        </Card.Body>
        <Card.Footer className="d-flex gap-2 align-items-center justify-content-end">
          <CustomButton
            handleSubmit={onSubmit}
            onSave={isLoading}
            labelText={data?.ConfirmText}
            loadingLabelText={data?.loadingConfirmText}
            disabled={isLoading}
            style={{ height: "fit-content" }}
            variant="danger"
          />
          <Button
            variant="success"
            style={{ boxShadow: "none" }}
            onClick={handleClose}
            size="sm"
            disabled={isLoading}
          >
            Cancel
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

export default CommonConfirmPopup;
