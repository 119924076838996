import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Form, Image } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import defaultImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import { FaUserEdit } from "react-icons/fa";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import ListLoading from "module/common/ListLoading";
import { TiDelete } from "react-icons/ti";
import CommonConfirmPopup from "components/common/Confirm/CommonConfirmPopup";
import { StoreContext } from "context/Context";

function UpdateAssigneeListDropDown({
  url,
  getData,
  active = {},
  itemId = "",
  method = "patch",
  clearable = false,
  iconColor = "black",
  afterUpdate,
  setAssigneeUpdateLoading,
}) {
  const [dataProcessing, setDataProcessing] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [assignees, setAssignees] = useState([]);
  const apiCallDelayRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const searchInputRef = useRef(null);
  const [unAssigning, setAssigning] = useState(false);
  const { store, addIitemToStore } = useContext(StoreContext);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const getOptions = (inputValue) =>
    new Promise((resolve, reject) => {
      setDataProcessing(true);
      try {
        if (apiCallDelayRef?.current) clearTimeout(apiCallDelayRef.current);
        apiCallDelayRef.current = setTimeout(
          async () => {
            const response = await axios.get("user/users-autocomplete", {
              params: {
                name: inputValue,
              },
            });

            let result = response?.data?.data;
            result = result?.data ? result?.data : result;

            let options = [];
            if (Array.isArray(result)) {
              options = result;
            }
            resolve(options);
            setDataProcessing(false);
          },
          inputValue?.length > 0 ? 1200 : 0
        );
      } catch (error) {
        console.error("error", error);
        reject(error);
        setDataProcessing(false);
      }
    });

  const fetchData = async (inputValue) => {
    const options = await getOptions(inputValue);
    if (inputValue?.length === 0) {
      addIitemToStore("usersList", options);
    }
    setAssignees(options);
  };

  useEffect(() => {
    if (isOpen) {
      if (searchParams?.length === 0 && store?.usersList?.length === 0) {
        fetchData(searchParams);
      } else {
        setAssignees(store?.usersList || []);
      }
    }

    if (isOpen) {
      if (searchParams?.length > 0) {
        fetchData(searchParams);
      }
    }

    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isOpen]);

  useEffect(() => {
    return () => {
      setSearchParams("");
    };
  }, [isOpen]);

  const getPrams = (id = "") => {
    if (url?.includes("task")) {
      return {
        task_id: itemId,
        remove_assignee: active?.value,
        new_assignee: id,
      };
    } else {
      return {
        assigned_to: id,
      };
    }
  };

  const handleSubmit = (id = "", unAssign = false) => {
    if (setAssigneeUpdateLoading) {
      if (!unAssign) {
        setAssigneeUpdateLoading(true);
      }
    } else {
      if (!unAssign) {
        setSubmitLoading(true);
      }
    }
    if (unAssign) {
      setAssigning(true);
    }
    axios({
      method: method,
      url: url,
      params: getPrams(id),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          if (getData) getData();
          if (afterUpdate) afterUpdate(response?.data?.data);
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.error("error", error);
        showToast(error.response.data.message, "error");
      })
      .finally(() => {
        if (setAssigneeUpdateLoading) {
          if (!unAssign) {
            setAssigneeUpdateLoading(false);
          }
        } else {
          if (!unAssign) {
            setSubmitLoading(false);
          }
        }
        if (unAssign) {
          setAssigning(false);
        }
        setShow(false);
      });
  };

  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const unAssigneeObject = {
    title: "Remove Assignee",
    message: `Do you want to remove ${active?.label} as assignee from this Task ?`,
    loadingConfirmText: "Removing...",
    ConfirmText: "Yes",
  };

  return (
    <>
      <Dropdown show={isOpen} autoClose="outside" onToggle={handleToggle}>
        <Dropdown.Toggle
          bsPrefix="toggle"
          variant="transparent"
          className="custom-transperent-common-button p-0 shadow-none"
        >
          <FaUserEdit
            size={17}
            color={iconColor}
            className="cursor-pointer"
            title="Update Assignee"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{ cursor: "default", minWidth: "230px" }}
          className="dropdown-menu-card dropdown-menu-end fs--1"
        >
          <Form.Control
            type="text"
            onChange={(e) => setSearchParams(e.target.value)}
            value={searchParams}
            className="mb-2 px-2"
            isLoading={dataProcessing}
            placeholder="Search..."
            ref={searchInputRef}
          />
          {active?.value && (
            <Dropdown.Item
              className={`text-dark d-flex align-items-center justify-content-between gap-1 p-1 custom-select-dropdown-item active mb-2`}
            >
              <span>
                <Image
                  src={active?.image ?? defaultImage}
                  roundedCircle
                  className="collaborator-dropdown-avatar"
                  alt={active?.label}
                />
                <span className="me-2">{active?.label}</span>
              </span>
              {clearable && (
                <TiDelete
                  size={24}
                  color="white"
                  onClick={(e) => handleShow(e)}
                  className="border-danger"
                  title="Remove assignee"
                />
              )}
            </Dropdown.Item>
          )}
          {dataProcessing ? (
            <Dropdown.Item className="custom-select-dropdown-item inactive text-center p-0">
              <ListLoading
                style={{
                  height: "170px",
                  maxHeight: "250px",
                  overflow: "hidden",
                }}
              />
            </Dropdown.Item>
          ) : assignees?.length > 0 ? (
            <SimpleBar
              style={{
                maxHeight: "250px",
              }}
              className="custom-simplebar-content d-flex flex-column gap-1"
            >
              {assignees
                ?.filter((assignee) => assignee?.id !== active?.value)
                .map((assignee, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      style={{
                        fontSize: "13px",
                        cursor: "pointer",
                        minWidth: "210px",
                      }}
                      className={`text-dark d-flex align-items-center gap-1 p-1 custom-select-dropdown-item ${
                        active?.value === assignee?.id ? "active" : ""
                      }`}
                      onClick={() => {
                        handleSubmit(assignee?.id);
                      }}
                    >
                      <Image
                        src={assignee?.profile_image ?? defaultImage}
                        roundedCircle
                        className="collaborator-dropdown-avatar"
                        alt={assignee?.full_name}
                      />
                      <span className="me-2">{assignee?.full_name}</span>
                    </Dropdown.Item>
                  );
                })}
            </SimpleBar>
          ) : (
            <Dropdown.Item className="custom-select-dropdown-item inactive text-center">
              <span>No Data Found</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {submitLoading && <LoadingOverlay />}
      {show && (
        <CommonConfirmPopup
          show={show}
          handleClose={() => setShow(false)}
          onSubmit={() => handleSubmit("", true)}
          data={unAssigneeObject}
          isLoading={unAssigning}
        />
      )}
    </>
  );
}

export default UpdateAssigneeListDropDown;
